/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import './global.js';

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

// pages for this product
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import ReseauPage from "views/PresentationPage/ReseauPage.js";
import InscriptionPage from "views/inscription/InscriptionPage.js";
import AssistanteDentairePage from "views/formation/AssistanteDentairePage.js";
import AuxiliaireVetoPage from "views/formation/AuxiliaireVetoPage.js";
import SecretaireMedicalePage from "views/formation/SecretaireMedicalePage.js";
import StructureAnimalierePage from "views/formation/StructureAnimaliere.js";
import AepePage from "views/formation/AepePage.js";
import Juridique from "views/formation/Juridique.js";
import ProfessionnalisationPage from "views/entreprise/ProfessionnalisationPage.js";
import FormOfferPage from "views/entreprise/FormOfferPage.js";
import ApprentissagePage from "views/entreprise/ApprentissagePage.js";
import ContactPage from "views/contact/ContactPage.js";
import ActuPage from "views/actu/ActuPage.js";
import FormPage from "views/inscription/FormPage.js";
import LoginPage from "views/login/LoginPage.js";
import MentionsPage from "views/legal/LegalPage.js";
import QualiteFormationPage from "views/PresentationPage/QualiteFormationPage.js";
import QualiteApprentissagePage from "views/PresentationPage/QualiteApprentissagePage.js";
import QualiopiPage from "views/PresentationPage/QualiopiPage.js";
import AdminPage from "layouts/Admin.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/inscription" component={InscriptionPage} />
      <Route path="/assistante-dentaire" component={AssistanteDentairePage} />
      <Route path="/auxiliaire-veterinaire" component={AuxiliaireVetoPage} />
      <Route path="/structure-animaliere" component={StructureAnimalierePage} />
      <Route path="/secretaire-medicale" component={SecretaireMedicalePage} />
      <Route path="/aepe" component={AepePage} />
      <Route path="/juridique" component={Juridique} />
      <Route path="/professionnalisation" component={ProfessionnalisationPage} />
      <Route path="/apprentissage" component={ApprentissagePage} />
      <Route path="/reseau" component={ReseauPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/actu" component={ActuPage} />
      <Route path="/inscription-form" component={FormPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/admin" component={AdminPage} />
      <Route path="/legal" component={MentionsPage} />
      <Route path="/proposition" component={FormOfferPage} />
      <Route path="/qualite-formation" component={QualiteFormationPage} />
      <Route path="/qualite-apprentissage" component={QualiteApprentissagePage} />
      <Route path="/qualiopi" component={QualiopiPage} />
      <Route path="/" component={PresentationPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
