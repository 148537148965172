import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ActuPageStyle.js";
const useStyles = makeStyles(presentationStyle);

function ActuLoading(Component) {
  const classes = useStyles();
  return function ActuLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return (
      <div className={classes.container}>
      <div className={classes.section}>
        <div className={classes.justifyContentCenter}>
          <GridContainer direction="row"
            justify="center"
            alignItems="center">
            <GridItem xs={12} sm={1} md={1}>
              <CircularProgress color="inherit" />
            </GridItem>
            <GridItem xs={12} sm={11} md={11}>
              <h3 className={classes.titleLoading}>Chargement du fil d'actualité. Veuillez Patientez ... </h3>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      </div>
    );
  };
}
export default ActuLoading;
