/*eslint-disable*/
import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { DateTime } from "luxon";
// nodejs library that concatenates classes
import classNames from "classnames";

import ActuLoading from "./ActuLoading"
import FilPage from "./FilPage"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Favorite from "@material-ui/icons/Favorite";
import Share from "@material-ui/icons/Share";
import ChatBubble from "@material-ui/icons/ChatBubble";
import Schedule from "@material-ui/icons/Schedule";
import TrendingUp from "@material-ui/icons/TrendingUp";
import Subject from "@material-ui/icons/Subject";
import WatchLater from "@material-ui/icons/WatchLater";
import People from "@material-ui/icons/People";
import Business from "@material-ui/icons/Business";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import Bookmark from "@material-ui/icons/Bookmark";
import Refresh from "@material-ui/icons/Refresh";
import Receipt from "@material-ui/icons/Receipt";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";

import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";

import cardBlog1 from "assets/img/campus2.jpg";
import cardBlog2 from "assets/img/campus1.png";
import cardBlog3 from "assets/img/campus3.jpg";

import logo_ecosup from "assets/img/ecosup.png"

// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ActuPageStyle.js";

const useStyles = makeStyles(presentationStyle);

const units: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

var path = require("path");

function timeAgo(dateTime) {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';
  const relativeFormatter = new Intl.RelativeTimeFormat('fr', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

export default function ActuPage() {

  const [blogs, setBlogs] = useState([]);

  const ListLoading = ActuLoading(FilPage);
  const [appState, setAppState] = useState({
    loading: true,
    blogs: null,
  });

  useEffect(() => {
      setAppState({ loading: true });
      axios.get(`${global.API_URL}/articles`)
      .then(res => {
        console.log(res.data);
        let blogs = res.data;
        blogs.sort((a,b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1);

        setBlogs(blogs);
        console.log(blogs);
        setAppState({ loading: false, blogs: blogs });
      });
  }, []);

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/actualites.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h1 className={classes.title}>
                      Actualités
                    </h1>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <ListLoading isLoading={appState.loading} blogs={appState.blogs}/>
      </div>
      <SectionFooter/>
    </div>
  );
}
