/*eslint-disable*/
import React from "react";
import { useState, useEffect } from "react";
import { useHistory} from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
import Face from "@material-ui/icons/Face";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import adminPageStyle from "assets/jss/material-kit-pro-react/views/echosup/AdminStyle.js";

import image from "assets/img/admin.jpg";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(adminPageStyle);

export default function LoginPage() {

  const [isLoading, setIsLoading] = useState(false);
  const [dataUser, setDataUser] = useState({ email: "", password: ""});
  const [errorLoggin, setErrorLoggin] = useState(false);
  const history = useHistory();

  const handleCloseLogginError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorLoggin(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  async function  identifyUser() {
    setIsLoading(true);
    axios.post(`${global.API_URL}/auth/local`, {
    identifier: dataUser.email,
    password: dataUser.password,
  }).then(response => {
    setIsLoading(false);
    console.log("login ok :) "+response.data.jwt);
    localStorage.setItem('jwt', response.data.jwt);
    history.push('/admin/messages');
  }).catch(err => {
    console.log("error: "+err);
    setErrorLoggin(true);
  });
  }

  return (
    <div>
      <Header
        absolute
        color="transparent"
        links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  <CardHeader
                    color="success"
                    signup
                    className={classes.cardHeader}
                  >
                    <h4 className={classes.cardTitle}>Login</h4>
                  </CardHeader>
                  <CardBody signup>
                    <CustomInput
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setDataUser({ ...dataUser, email: e.target.value }),
                        placeholder: "Email...",
                        type: "email",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      id="pass"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setDataUser({ ...dataUser, password: e.target.value }),
                        placeholder: "Password",
                        type: "password",
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon className={classes.inputIconsColor}>
                              lock_utline
                            </Icon>
                          </InputAdornment>
                        ),
                        autoComplete: "off"
                      }}
                    />
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button simple color="success" size="lg" onClick={identifyUser}>
                      {isLoading ? "Identification ... " : "s'identifier"}
                    </Button>
                  </div>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Snackbar open={errorLoggin} autoHideDuration={5000} onClose={handleCloseLogginError}>
          <Alert onClose={handleCloseLogginError} severity="error">
            Erreur : Le nom d'utilisateur ou le mot de passe est incorrect.
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}
