
/*eslint-disable*/
import React from "react";
// @material-ui/core components
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ReseauStyle.js";

import SectionFooter from "views/footer/PageFooter.js"

import certificat from "assets/img/certificat.webp";

const useStyles = makeStyles(presentationStyle);

export default function QualiopiPage() {

const classes = useStyles();

return (
  <div>
    <Header
      links={<HeaderLinks dropdownHoverColor="info" />}
      fixed
      color="transparent"
      changeColorOnScroll={{
        height: 50,
        color: "white"
      }}
    />
    <Parallax
      image={require("assets/img/qualite.jpg")}
      className={classes.parallax}
      filter="dark"
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <div className={classes.brand}>
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12} sm={8} md={8}>
                  <h1 className={classes.titleTop}>
                    Certification Qualiopi
                  </h1>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Parallax>
    <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
          <GridContainer direction="row" justify="left" alignItems="center">
            <GridItem xs={12} sm={12} md={8}>
              <img
                src={certificat} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleJustifyQualiopi}>QU'EST-CE QUE LA CERTIFICATION QUALIOPI</h2>
            <p className={classes.descriptionJustifyQualiopi}>Obligatoire depuis le 1er janvier 2022, la certification QUALIOPI est obligatoire pour bénéficier de fonds publics et mutualisés.</p>
            <p className={classes.descriptionJustifyQualiopi}>Elle garantit la qualité des prestations proposées par les organismes de formation professionnelle et les centres de formation d’apprentis.</p>
            <p className={classes.descriptionJustifyQualiopi}>Elle vise à :</p>
            <p className={classes.descriptionJustifyQualiopi}>- attester de la qualité du processus mis en œuvre par les prestataires d’actions concourant au développement des compétences</p>
            <p className={classes.descriptionJustifyQualiopi}>- permettre une plus grande lisibilité de l’offre de formation auprès des entreprises et des usagers</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleJustifyQualiopi}>LES 4 CATÉGORIES D'ACTION</h2>
            <p className={classes.descriptionJustifyQualiopi}>Il existe quatre catégories d’actions concourant au développement des compétences :</p>
            <p className={classes.descriptionJustifyQualiopi}>- Organismes de Formation (OF) : actions décrites au L. 6313-1-1°</p>
            <p className={classes.descriptionJustifyQualiopi}>- Centres de Bilan de Compétences (CBC) : actions décrites au L. 6313-1-2°</p>
            <p className={classes.descriptionJustifyQualiopi}>- Prestataires de Validation des Acquis de l’Expérience (VAE) : actions décrites au L. 6313-1-3°</p>
            <p className={classes.descriptionJustifyQualiopi}>- Centres de Formation d’Apprentis (CFA) : actions décrites au L. 6313-1-4°</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleJustifyQualiopi}>LES POINTS DE CONTRÔLE</h2>
            <p className={classes.descriptionJustifyQualiopi}>Cette certification est obtenue après la vérification de la conformité des formations aux critères imposés par le Référentiel National Qualité par une entité compétente (7 critères et 32 points de contrôle).</p>
            <p className={classes.descriptionJustifyQualiopi}>Ce référentiel est organisé autour de 7 critères qualité :</p>
            <p className={classes.descriptionJustifyQualiopi}>1 - Conditions d’information du public sur les prestations proposées, les délais pour y accéder et les résultats obtenus.</p>
            <p className={classes.descriptionJustifyQualiopi}>2 - Identification précise des objectifs des prestations proposées et l’adaptation de ces prestations aux publics bénéficiaires, lors de la conception des prestations.</p>
            <p className={classes.descriptionJustifyQualiopi}>3 - Adaptation aux publics bénéficiaires des prestations et des modalités d’accueil, d’accompagnement, de suivi et d’évaluation mises en œuvre.</p>
            <p className={classes.descriptionJustifyQualiopi}>4 - Adéquation des moyens pédagogiques, techniques et d’encadrement aux prestations mises en œuvre.</p>
            <p className={classes.descriptionJustifyQualiopi}>5 - Qualification et développement des connaissances et compétences des personnels chargés de mettre en œuvre les prestations.</p>
            <p className={classes.descriptionJustifyQualiopi}>6 - Inscription et investissement du prestataire dans son environnement professionnel.</p>
            <p className={classes.descriptionJustifyQualiopi}>7 - Recueil et prise en compte des appréciations et des réclamations formulées par les parties prenantes aux prestations délivrées.</p>
            <p className={classes.descriptionJustifyQualiopi}>Un cahier des charges est établi et doit être respecté</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleJustifyQualiopi}>POURQUOI EST-CE IMPORTANT ?</h2>
            <p className={classes.descriptionJustifyQualiopi}>C’est un gage d’efficacité, de professionnalisme, de transparence et de rigueur.</p>
            <p className={classes.descriptionJustifyQualiopi}>C’est un engagement de proposer des formations de qualité dans le but d’une amélioration continue de nos formations.</p>
            <p className={classes.descriptionJustifyQualiopi}>L’organisme certifié fait l’objet d’audits de surveillance pour vérifier que les critères de qualité soient toujours respectés.</p>
            </GridItem>
          </GridContainer>
          </div>
        </div>
    </div>
    <SectionFooter/>
 </div>
   );
}