import {
  container,
  title,
  main,
  whiteColor,
  section,
  grayColor,
  mainRaised,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/customSelectStyle.js";

const presentationStyle = {
  ...footerStyle,
  ...customSelectStyle,
  main: {
    ...main
    /*overflow: "hidden"*/
  },
  mainRaised,
  parallax: {
    height: "50vh",
    overflow: "hidden"
  },
  container: {
    ...container,
    zIndex: 1
  },
  section: {
    ...section,
    padding: "40px 0px",
    "& h4$description": {
      fontSize: "1.5em"
    }
  },

  sectionCenter: {
    ...section,
    textAlign: "center",
    padding: "40px 0px",
    "& h4$description": {
      fontSize: "1.5em"
    }
  },
  description: {
    color: grayColor[0],
    textAlign: "center"
  },
  title: {
    ...title,
    color: whiteColor
  },
  title2: {
    ...title,
    textAlign: "center"
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative"
    }
  },
  proBadge: {
    position: "relative",
    fontSize: "22px",
    textTransform: "uppercase",
    fontWeight: "700",
    right: "-10px",
    padding: "10px 18px",
    top: "-30px",
    background: whiteColor,
    borderRadius: "3px",
    color: grayColor[18],
    lineHeight: "22px",
    boxShadow: "0 5px 5px -2px rgba(" + hexToRgb(grayColor[25]) + ",.4)"
  },
  stats: {
    color: grayColor[0],
    display: "flex",
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      fontSize: "18px",
      lineHeight: "18px"
    },
    "& svg": {
      position: "relative",
      top: "3px",
      marginRight: "3px",
      marginLeft: "3px",
      width: "18px",
      height: "18px"
    }
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
  cardTitleWhite: {
    "&, & a": {
      ...title,
      marginTop: ".625rem",
      marginBottom: "0",
      minHeight: "auto",
      color: whiteColor + " !important"
    }
  },
  cardDescriptionWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)"
  },
  buttonCenter: {
    textAlign: "center",
  },
  modalTitle: {
    ...title,
    textAlign: "center",
    margin: "0",
  },
  modalBody: {
    textAlign: "center",
    color: grayColor[0],
    paddingBottom: "12px",
    paddingLeft: "50px",
    paddingRight: "50px",
    position: "relative"
  },
  modalFooter: {
    padding: "15px",
    textAlign: "right",
    paddingTop: "0",
    margin: "0"
  },
  modalFooterCenter: {
    marginLeft: "auto",
    marginRight: "auto",
    paddingBottom: "30px",
  }
};

export default presentationStyle;
