/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ApprentissageStyle.js";

import logo_ecosup from "assets/img/ecosup.png"

const useStyles = makeStyles(presentationStyle);

export default function ApprentissagePage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/apprentissage.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h2 className={classes.title}>
                      Apprentissage
                    </h2>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer justify="center" alignItems="center">
              <GridItem xs={12} sm={8} md={8}>
                <h2 className={classes.title2}>Pourquoi recruter en contrat d’apprentissage ?</h2>
                <br/>
                  <h5 className={classes.description}>
                    Ce système de recrutement vous permet d’intégrer et former un nouveau salarié à vos techniques et à l’image de votre structure, de donner une dynamique de transmission de savoir-faire dans votre entreprise tout en maîtrisant votre investissement.
                  </h5>
                  <br/>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Card color="info">
                  <CardBody color>
                    <h4 className={classes.cardTitleWhite}>
                        Vous avez une <b>offre de poste à pourvoir en alternance</b> et recherchez des candidats?
                    </h4>
                    <p className={classes.cardDescriptionWhite}>
                      Découvrez notre accompagnement sur mesure
                    </p>
                    <div
                      className={
                        classes.stats + " " + classes.justifyContentCenter
                      }
                    >
                      <Link to="/proposition" >
                      <Button color="white" round>
                        En savoir plus
                      </Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>

        <div className={classes.section_sub}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem md={12} sm={12}>
                <h4 className={classes.title_sub}>Les avantages financiers du contrat d’apprentissage :</h4>
                <br/>
                  <p className={classes.description_sub}>
                    Le contrat d’apprentissage est un contrat de travail conclu entre un employeur et un salarié. Sous forme de <b>CDD ou CDI, de 6 à 24 mois</b>, ce contrat dispose d’une période d’essai de 45 jours. Le titulaire d’un contrat d’apprentissage est un salarié à part entière. Le temps de formation est inclus dans le temps de travail et <b>les coûts de formation sont pris en charge par l’OPCO de l’entreprise</b>. Le salaire versé par l’employeur est défini sur la base légale de rémunération.
                    <br/>
                    <br/>
                    <ul>
                       <li>AFE : Aide Forfaitaire Employeur jusqu’à 2000€ (aide de la part de France Travail pour les + de 26 ans).</li>
                       <li>Aide aux plus de 45 ans jusqu’à 2000€ (aide de la part de France Travail cumulable avec l’AFE).</li>
                    </ul>
                  </p>
                </GridItem>
              </GridContainer>
          </div>
        </div>
        <div className={classes.section_sub}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem md={12} sm={12}>
                <h4 className={classes.title_sub}>Les plus :</h4>
                <br/>
                  <p className={classes.description_sub}>
                    <ul>
                       <li>L’assistant(e) embauché(e) n’est pas un(e) étudiant(e) mais un(e) salarié(e).</li>
                       <li>La structure juridique verse une rémunération en pourcentage du SMIC au salarié. Aucune indemnité de fin de contrat n’est due.</li>
                       <li>L’assistant(e) en alternance n’est pas comptabilisé(e) dans l’effectif de l’entreprise.</li>
                    </ul>
                  </p>
                </GridItem>
              </GridContainer>
          </div>
        </div>


      <div className={classes.section_sub}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={8}
              className={classes.mrAuto + " " + classes.mlAuto}
            >
              <h4 className={classes.title_sub}>Les rémunérations :</h4>
              <br/>
                <p className={classes.description_sub}>
                  <Table
                    tableHead={[
                      "Âge",
                      "% du SMIC",
                      "Salaire Brut salarié (€)"
                    ]}
                    tableData={[
                      [
                        "de 18 ans à 20 ans",
                        "1ère année (43%) / 2ème année (51%)",
                        "759,78€ / 901,13€"
                      ],
                      [
                        "de 21 ans à 25 ans",
                        "1ère année (53%) / 2ème année (61%)",
                        "936.47€ / 1077.82€"
                      ],
                      [
                        "26 ans et +",
                        "100%",
                        "1766.92€"
                      ]
                    ]}
                    customCellClasses={[
                      classes.textCenter,
                      classes.textCenter,
                      classes.textCenter
                    ]}
                    customClassesForCells={[0, 1, 2]}
                    customHeadCellClasses={[
                      classes.textCenter,
                      classes.textCenter,
                      classes.textCenter
                    ]}
                    customHeadClassesForCells={[0, 1, 2]}
                  />
                </p>
              </GridItem>
            </GridContainer>

        </div>
      </div>


      <br/>
      <br/>
    </div>

    <SectionFooter/>
    </div>
  );
}
