/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import TextField from '@material-ui/core/TextField';

import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";

import legalStyle from "assets/jss/material-kit-pro-react/views/echosup/ContactStyle.js";

import logo_ecosup from "assets/img/ecosup.png"

const useStyles = makeStyles(legalStyle);

export default function LegalPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/mentions.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container2}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={6} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h1 className={classes.titleTop}>
                      Mention Légales
                    </h1>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Mention Légales</h2>
          </div>
        </div>
      </div>

      <Footer
        theme="transparent"
        content={
          <div>
            <div className={classes.pullCenter}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    href="/legal"
                    className={classes.block}
                  >
                    Mentions Légales
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    to="/contact"
                    className={classes.block}
                  >
                    Contact
                  </Link>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <Link
                    to="/login"
                    className={classes.block}
                  >
                    Administration
                  </Link>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , EcoSup Toulon. Tous droits réservés
            </div>
          </div>
        }
      />
    </div>
  );
}
