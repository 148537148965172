/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import GetAppIcon from '@material-ui/icons/GetApp';
import FolderIcon from '@material-ui/icons/Folder';
import PeopleIcon from '@material-ui/icons/People';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import SchoolIcon from '@material-ui/icons/School';
import ComputerIcon from '@material-ui/icons/Computer';
import TodayIcon from '@material-ui/icons/Today';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AssignmentIcon from '@material-ui/icons/Assignment';
// core components
import InfoArea from "components/InfoArea/InfoArea.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/SecretaireMedicaleStyle.js";

import logo_ecosup from "assets/img/ecosup.png";

import imgSecMed from "assets/img/secmed.jpg";

import part1 from "assets/img/partenaire_vidal/API.jpg";
import part2 from "assets/img/partenaire_vidal/COL.jpg";
import part3 from "assets/img/partenaire_vidal/GHICL.jpg";
import part4 from "assets/img/partenaire_vidal/MCO1.jpg";
import part5 from "assets/img/partenaire_vidal/rbx.jpg";
import part6 from "assets/img/partenaire_vidal/SA.jpg";

import logo from "assets/img/logo/esad.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(presentationStyle);

export default function SecretaireMedicalePage() {
  React.useEffect(() => {
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  retrieveSessions();
},[]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalDownload, setModalDownload] = React.useState(false);
  const [data, setData] = useState({ nom: "", prenom: "", email:"", phone:""});
  const [errors, setErrors] = useState({});

  function handleValidation(){
    let formIsValid = true;
    let errors = {};

    //Name
    let name = `${data.nom}`;
    if(name.trim() == ""){
       formIsValid = false;
       errors["name"] = "*Veuillez entrer un nom";
    }else{
      if(typeof name !== "undefined"){
         if(!name.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["name"] = "*Le nom doit comporter uniquement des caratères";
         }
      }
    }

    //Prenom
    let prenom = `${data.prenom}`;
    if(prenom.trim() == ""){
       formIsValid = false;
       errors["prenom"] = "*Veuillez entrer un prénom";
    }else{
      if(typeof prenom !== "undefined"){
         if(!prenom.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["prenom"] = "*Le prénom doit comporter uniquement des caratères";
         }
      }
    }

    //Email
    let email = `${data.email}`;
    if(email.trim() == ""){
       formIsValid = false;
       errors["email"] = "*Veuillez entrer une adresse email";
    }else{
      if(typeof email !== "undefined"){
         let lastAtPos = email.lastIndexOf('@');
         let lastDotPos = email.lastIndexOf('.');

         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "*Veuillez entrer une adresse email valide";
          }
     }
    }

    //Phone
    let phone = `${data.phone}`;
    if(phone.trim() == ""){
       formIsValid = false;
       errors["phone"] = "*Veuillez entrer un numéro de téléphone";
    }else{
      if(typeof phone !== "undefined"){
         if(!phone.match(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/)){
            formIsValid = false;
            errors["phone"] = "*Veuillez entrer un numéro de téléphone valide";
         }
      }
    }

    if(!checkbox){
      formIsValid = false;
      errors["checkbox"] = "*Veuillez acceptez l'enregistrement de vos informations";
    }

   setErrors(errors);
   console.log(errors);
   return formIsValid;
  }

  const [checkbox, setCheckbox] = useState(false);
  const handleCheckbox = (event) => {
    errors["checkbox"]="";
    setCheckbox(event.target.checked);
  };

  function download() {
    let isValid = handleValidation();
    if(isValid){
      window.open(`${global.url_plaquette_vidal}`);
      setIsLoading(true);
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           nom: `${data.nom}`,
           prenom: `${data.prenom}`,
           email: `${data.email}`,
           phone: `${data.phone}`,
           formation: 1
         }))

      axios.post(`${global.API_URL}/downloads`,dataForm
      ).then(function(response){
      console.log('Success!');
      setModalDownload(false);
      setIsLoading(false);
    })
    .catch(function(){
      console.log('Fail!');
    });
    }
  }

  const [sessions, setSessions] = useState([]);
  const [inscriptionEnabled, setInscriptionEnabled] = useState(false);
  function retrieveSessions()
  {
    let userjwt = localStorage.getItem('jwt');
    axios.get(`${global.API_URL}/session-informations`)
    .then(res => {

      let listSessions = [];
      for (let [index,session] of res.data.entries()) {
        console.log(session);

        const isFound = session.formations.some(element => {
          if (element.id === 1) {
            return true;
          }
        });

        if(isFound){
          let date = moment(session.date).format('DD/MM/yyyy');
          let time = moment(session.date).format('HH:mm');
          let s={text:date+" "+time,id:session.id};
          listSessions.push(s);
        }
      }
      if(listSessions.length>0)setInscriptionEnabled(true);
      setSessions(listSessions);
    });
  }

  const [sessionSelect, setSessionSelect] = React.useState("");
  const handleSessionSelect = event => {
    let code = event.target.value;
    setSessionSelect(event.target.value);
    console.log("Session : "+code);
  }

  const [modalInscription, setModalInscription] = React.useState(false);
  function registerRDV(){
    let isValid = handleValidation();
    if(isValid){
      setIsLoading(true);
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           nom: `${data.nom}`,
           prenom: `${data.prenom}`,
           email: `${data.email}`,
           phone: `${data.phone}`,
           session_information: sessionSelect
         }))

      axios.post(`${global.API_URL}/rdv-sessions`,dataForm
      ).then(function(response){
      console.log('Success!');
      setModalInscription(false);
      setSuccessRegister(true);
      setIsLoading(false);
    })
    .catch(function(){
      console.log('Fail!');
    });
    }
  }

  const [successRegister, setSuccessRegister] = useState(false);
  const handleCloseRegister = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessRegister(false);
  };

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/secretaire.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h1 className={classes.title}>
                      Secrétaire médicale
                    </h1>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={8} md={8}>
            <h3 className={classes.title2}>Prérequis</h3>
            <div className={classes.features}>
              <GridContainer>
                <GridItem xs={12} sm={3} md={3}>
                  <InfoArea
                    vertical
                    icon={SchoolIcon}
                    description="Niveau bac"
                    iconColor="vidal"
                  />
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <InfoArea
                    vertical
                    icon={AssignmentIcon}
                    description="Bon niveau en orthographe"
                    iconColor="vidal"
                  />
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <InfoArea
                    vertical
                    icon={ComputerIcon}
                    description="Maîtrise du clavier et de la bureautique"
                    iconColor="vidal"
                  />
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <InfoArea
                    vertical
                    icon={PeopleIcon}
                    description="Sens du relationnel"
                    iconColor="vidal"
                  />
                </GridItem>
              </GridContainer>

            </div>
            <h5 className={classes.description}>Pour les blocs de compétences, équivalences, passerelles et suite de parcours se rapprocher de Olivier Dufour au {global.phone_olivier}</h5>
            <h3 className={classes.cardTitle}>Objectifs</h3>
            <h5 className={classes.description}>Rendre les stagiaires <b>immédiatement opérationnels</b>, en développant des connaissances médicales et bureautiques.
Élaborée avec le soutien et la participation de médecins et professionnels de la santé, cette formation est fortement axée sur la pratique des tâches d’un secrétariat médical ou secrétariat médico-social.
</h5>
            <h3 className={classes.cardTitle}>Programme de Formation</h3>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <h5 className={classes.description}>✓ Droit médical</h5>
                  <h5 className={classes.description}>✓ Droit de la sécurité sociale</h5>
                  <h5 className={classes.description}>✓ Bureautique</h5>
                  <h5 className={classes.description}>✓ Techniques de secrétariat</h5>
                  <h5 className={classes.description}>✓ Dictaphone</h5>
                  <h5 className={classes.description}>✓ Terminologie</h5>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <h5 className={classes.description}>✓ Anatomie physiologie pathologie</h5>
                  <h5 className={classes.description}>✓ Examens cliniques et para-cliniques</h5>
                  <h5 className={classes.description}>✓ Hygiène</h5>
                  <h5 className={classes.description}>✓ Pharmacologie</h5>
                  <h5 className={classes.description}>✓ Relationnel Professionnel</h5>
                </GridItem>
              </GridContainer>
            <h3 className={classes.cardTitle}>Validation</h3>
            <h5 className={classes.description}>Certification professionnelle de niveau 4, numéro 1615, de Secrétaire Médical.e délivrée par Ecole Vidal-Vidal formation selon arrêté du 07 juillet 2017 portant inscription au Répertoire National des Certifications Professionnelles (RNCP) par France Compétences, autorité de régulation et de financement de la formation professionnelle et de l'apprentissage.</h5>
            <h3 className={classes.cardTitle}>Débouchés</h3>
            <h5 className={classes.description}>Après l’obtention de votre certification professionnelle, vous pouvez intégrer un cabinet de médecins, laboratoire, centre de radiologie ou toute autre structure médicale en qualité de secrétaire médicale ou Secrétaire médico-sociale.</h5>
            </GridItem>

            <GridItem xs={12} sm={4} md={4}>
              <Card
                backgroundVidal
                style={{ backgroundImage: `url(${imgSecMed})` }}
              >
                <CardBody background>
                  <h3 className={classes.cardTitleWhite}>
                    Vous souhaitez devenir secrétaire médical(e)?
                  </h3>
                  <br/>
                  <h3 className={classes.cardTitleWhite}>
                    Rendez vous à notre prochaine session d'information
                  </h3>
                  <br/>
                  <br/>
                  <p className={classes.cardDescriptionWhite}>
                    <b>Impasse du Docteur Charles Barnier</b>
                  </p>
                  <p className={classes.cardDescriptionWhite}>
                    <b>83000 Toulon</b>
                  </p>
                  <br/>
                  <br/>
                  {inscriptionEnabled ?
                  <Button color="white" round onClick={() => {setModalInscription(true)}}>
                    Je m'inscris
                  </Button> : <h6>pas de session prévue pour l'instant</h6>}
                  <br/>
                  <br/>
                </CardBody>
              </Card>
              <div className={classes.buttonCenter}>
              <Button color="vidal" round onClick={() => {setModalDownload(true)}}>
                <GetAppIcon/>Télécharger la documentation
              </Button>
              </div>
              <h3 className={classes.cardTitle}>Public visé</h3>
              <h5 className={classes.description}>Demandeur d'emploi</h5>
              <h5 className={classes.description}>Etudiant</h5>
              <h5 className={classes.description}>Salarié en reconversion</h5>
              <h3 className={classes.cardTitle}>Dispositif de formation</h3>
              <h5 className={classes.description}>Formation en <b>alternance</b> : 18 mois,
1j/semaine – Rentrées permanentes</h5>
              <h5 className={classes.description}>VAE (Validation des Acquis de l’Expérience)</h5>
            </GridItem>
          </GridContainer>
          </div>
        </div>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalDownload}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalDownload(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          maxWidth="md"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Téléchargez la documentation</h4>
          <p className={classes.description}>Veuillez remplir le formulaire pour accéder au téléchargement</p>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Nom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Prénom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
            </GridItem>
          </GridContainer>
              <CustomInput
                labelText="Téléphone"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
              <CustomInput
                labelText="Email"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={handleCheckbox}
                  />
                }
                label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
              />
              <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
              <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {download()}} color="vidal" round>
              {isLoading ? "Veuillez patienter ... " : "Téléchargement"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalInscription}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalInscription(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          maxWidth="md"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Réunion d'informations à Toulon</h4>
          <p className={classes.features}>Lieu de la réunion : <b>Ecosup-Toulon</b></p>
          <p className={classes.features}>177 Boulevard Charles Barnier, 83000 Toulon</p>
          <p className={classes.features}>(accés depuis l'impasse Barnier)</p>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Session
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={sessionSelect}
                onChange={handleSessionSelect}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
                >
                {sessions.map((session,index) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={session.id}
                    key={index}
                  >
                    {session.text}
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p style={{color: "red",fontSize: "12px"}}>{errors["session"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Nom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Prénom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
            </GridItem>
          </GridContainer>
              <CustomInput
                labelText="Téléphone"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
              <CustomInput
                labelText="Email"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={handleCheckbox}
                  />
                }
                label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
              />
              <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
              <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {registerRDV()}} color="vidal" round>
              {isLoading ? "Veuillez patienter ... " : "Inscription"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar open={successRegister} autoHideDuration={5000} onClose={handleCloseRegister}>
        <Alert onClose={handleCloseRegister} severity="success">
          Votre inscription a bien été enregistrée!
        </Alert>
      </Snackbar>
      <SectionFooter/>
    </div>
  );
}
