/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Gesture from "@material-ui/icons/Gesture";
import Build from "@material-ui/icons/Build";
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import GroupIcon from '@material-ui/icons/Group';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Button from "components/CustomButtons/Button.js";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import GetAppIcon from '@material-ui/icons/GetApp';
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import logo_ecosup from "assets/img/ecosup.png"

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/InscritpionStyle.js";

const useStyles = makeStyles(presentationStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function InscriptionPage() {

  const [isLoading, setIsLoading] = useState(false);
  const [modalDownload, setModalDownload] = React.useState(false);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const handleSimple = event => {
    let code = event.target.value;
    setSimpleSelect(event.target.value);
  }

  function download(){
    if(simpleSelect==="1"){
      window.open(`${global.url_dossier_vidal}`);
    }
    if(simpleSelect==="2"){
      window.open(`${global.url_dossier_supveto}`);
    }
    if(simpleSelect==="3"){
      window.open(`${global.url_dossier_esad}`);
    }
    if(simpleSelect==="5"){
      window.open(`${global.url_dossier_aepe}`);
    }
    if(simpleSelect==="6"){
      window.open(`${global.url_dossier_aj}`);
    }
  }

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/inscriptions.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h1 className={classes.title}>
                      Inscription
                    </h1>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            {/* <h2 className={classes.title2}>Conditions d'admission</h2>
            <h5 className={classes.description}>La formation est ouverte à tout élève <b>à partir de 18 ans</b>. Cette condition d’âge est obligatoire compte tenu des stages en milieu professionnel et notamment en service de radiologie eu égard à la législation applicable dans ce domaine.</h5>
            <h5 className={classes.description}>Cet enseignement professionnel est accessible <b>après toute terminale (ou niveau équivalent)</b> des filières générales, technologiques, professionnelles ou agricoles.</h5>
            <h5 className={classes.description}>Le dossier d’inscription doit être adressé au secrétariat. Le recrutement se fait sur les résultats scolaires obtenus dans les études passées, mais surtout <b>sur un entretien de motivation</b>. Vous serez convoquer à un entretien dans un délai maximum de 15 jours. À l’issue de l’entretien, la décision d’acceptation vous sera communiquée.</h5>
            <h5 className={classes.description}>Les stagiaires de la formation continue dans le cadre d’un plan de formation, de la VAE, du CPF ou d’un CPF de transition professionnelle (ex : CIF) peuvent aussi accéder à cette formation.</h5>
            <br/><br/> */}
            <h2 className={classes.title2}>Modalité et délais d'accès</h2>
            <h5 className={classes.description}>L'admission a EcoSup Toulon se fait <b>sans passer par ParcourSup</b>.</h5>
            <br/><br/>
            <GridContainer>
              <GridItem md={3} sm={3}>
                <InfoArea
                  title="Étape 1"
                  description={
                    <span>
                      <h5>
                        Je remplis un <b>dossier de candidature</b> et je prends un rendez vous individuel.
                      </h5>
                    </span>
                  }
                  icon={FolderSharedIcon}
                  iconColor="success"
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <InfoArea
                  title="Étape 2"
                  description={
                    <span>
                      <h5>
                        Je me présente au rendez vous et je réalise mon <b>test de positionnement</b> ainsi que mon <b>entretien de motivation</b>. Je repars avec mon contrat d'inscription.
                      </h5>
                    </span>
                  }
                  icon={GroupIcon}
                  iconColor="success"
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <InfoArea
                  title="Étape 3"
                  description={
                    <span>
                      <h5>
                        Je renvoie mon <b>contrat d'inscritpion</b> avec les pièces justificatives.
                      </h5>
                    </span>
                  }
                  icon={AssignmentIcon}
                  iconColor="success"
                />
              </GridItem>
              <GridItem md={3} sm={3}>
                <InfoArea
                  title="Étape 4"
                  description={
                    <span>
                      <h5>
                        Je reçois ma réponse d'admissibilité dans un délais de <b>7 jours</b> par mail et/ou courrier.
                      </h5>
                    </span>
                  }
                  icon={CheckBoxIcon}
                  iconColor="success"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <div className={classes.sectionCenter}>
          <div className={classes.container}>
            <h2 className={classes.title2}>Commencez le processus d'inscription</h2>
            <br/>
              <GridContainer>
              <GridItem md={12} sm={12}>
                <Button color="success" round onClick={() => {setModalDownload(true)}}>
                  <GetAppIcon/> Télécharger un dossier de candidature
                </Button>
              </GridItem>
            </GridContainer>
            <br/>
            <br/>
          </div>
        </div>
      </div>

      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={modalDownload}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setModalDownload(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
        <h4 className={classes.modalTitle}>Téléchargez le dossier de candidature</h4>
        <p className={classes.description}>Veuillez sélectionner la formation qui vous intéresse</p>
        </DialogTitle>
        <DialogContent
          id="modal-slide-description"
          className={classes.modalBody}
        >
        <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel
          htmlFor="simple-select"
          className={classes.selectLabel}
        >
          Formation
        </InputLabel>
        <Select
          MenuProps={{
            className: classes.selectMenu
          }}
          classes={{
            select: classes.select
          }}
          value={simpleSelect}
          onChange={handleSimple}
          inputProps={{
            name: "simpleSelect",
            id: "simple-select"
          }}
          >
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="3"
            >
              Assistante Dentaire
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="1"
            >
              Secrétaire Médicale
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="2"
            >
              Auxiliaire de Service Vétérinaire
            </MenuItem>
            {/* <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="4"
            >
              Chargé de Gestion et de Management en Structure Animalière
            </MenuItem> */}
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="5"
            >
              Accompagnant(e) éducatif petite enfance
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="6"
            >
              Assistant(e) juridique
            </MenuItem>
          </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + " " + classes.modalFooterCenter}
        >
          <Button onClick={() => {download()}} color="supvetobachelor" round>
            {isLoading ? "Veuillez patienter ... " : "Téléchargement"}
          </Button>
        </DialogActions>
      </Dialog>

      <SectionFooter/>
    </div>
  );
}
