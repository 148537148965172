/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import GetAppIcon from '@material-ui/icons/GetApp';
import BallotIcon from '@material-ui/icons/Ballot';
import InfoIcon from '@material-ui/icons/Info';

// core components/views for Admin layout
import BlogPage from "views/dashboard/BlogPage.js";
import InscriptionPage from "views/dashboard/InscriptionPage.js";
import MessagePage from "views/dashboard/MessagePage.js";
import DownloadPage from "views/dashboard/DownloadPage.js";
import ContratPage from "views/dashboard/ContratPage.js";
import SessionPage from "views/dashboard/SessionPage.js";

const dashboardRoutes = [
  {
    path: "/messages",
    name: "Messages",
    icon: LibraryBooks,
    component: MessagePage,
    layout: "/admin"
  },
  {
    path: "/downloads",
    name: "Téléchargements",
    icon: GetAppIcon,
    component: DownloadPage,
    layout: "/admin"
  },
  {
    path: "/inscriptions",
    name: "Inscription",
    icon: Person,
    component: InscriptionPage,
    layout: "/admin"
  },
  {
    path: "/contrats",
    name: "Contrats",
    icon: BallotIcon,
    component: ContratPage,
    layout: "/admin"
  },
  {
    path: "/blog",
    name: "Actualités",
    icon: Dashboard,
    component: BlogPage,
    layout: "/admin"
  },
  {
    path: "/session",
    name: "Sessions d'information",
    icon: InfoIcon,
    component: SessionPage,
    layout: "/admin"
  },
];

export default dashboardRoutes;
