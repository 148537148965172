import React from "react";
import { Link } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import InfoArea from "components/InfoArea/InfoArea.js";
import GroupIcon from '@material-ui/icons/Group';
//import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import SchoolIcon from '@material-ui/icons/School';
import AccessibleIcon from '@material-ui/icons/Accessibility';

import descriptionStyle from "assets/jss/material-kit-pro-react/views/echosup/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();

  return (
      <div className={classes.sectionBottom}>
      <div className={classes.container}>
        <h2 className={classes.title2}>Nos promesses</h2>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem md={12} sm={12}>
        <GridContainer>
          <GridItem md={4} sm={4}>
            <InfoArea
              title="Accompagnement et suivi"
              description={
                <span>
                  <h5>
                    Une équipe proche de ses apprenants à votre écoute et disponible. Nous vous accompagnons et vous aidons dans votre formation et dans vos projets. La qualité de nos formations et notre expérience font que nous avons pu bâtir un réseau d’entreprises partenaires qui alimentent en permanence des offres de stage, d’alternance et d’emploi. Notre expertise, notre <Link to="/reseau" style={{ color: "#00acc1" }}>réseau</Link> et notre notoriété vous permettront d’accéder au marché du travail.
                  </h5>
                </span>
              }
              icon={GroupIcon}
              iconColor="info"
            />
          </GridItem>
          {/*<GridItem md={4} sm={4}>
            <InfoArea
              title="Une expertise reconnue"
              description={
                <span>
                  <p>
                    Fort de sa notoriété, depuis 1980??, le groupe EcoSup est un centre de formation reconnu dans tous le pays, nous avons formé des milliers de personnes. Un aussi grand réseau permet d’avoir un grand nombre d’entreprises partenaires. Le <Link to="/reseau" style={{ color: "#00acc1" }}>réseau</Link> Ecosup c’est, 3 écoles supérieures ou organismes de formation, plus de 1700?? élèves, 220?? salariés et une trentaine de centres partenaires.
                  </p>
                </span>
              }
              icon={ThumbUpIcon}
              iconColor="default"
            />
          </GridItem>*/}
          <GridItem md={4} sm={4}>
            <InfoArea
              title="Titres certifiés"
              description={
                <span>
                  <h5>
                    Toutes les formations proposées sont validées par une certification professionnelle (enregistrée au <Link to={{ pathname: "https://www.francecompetences.fr/recherche/rncp/36734/" }} target="_blank" style={{ color: "#00acc1" }}>RNCP</Link>, Répertoire National de la Certification Professionnelle, par arrêté ministeriel). Choisissez une formation pour votre avenir dans le secteur <Link to={{ pathname: "https://vidal-formation.net/formation-en-alternance/" }} target="_blank" style={{ color: "#00acc1" }}>médical</Link>, <Link to={{ pathname: "https://esad-dentaire.com/" }} target="_blank" style={{ color: "#00acc1" }}>dentaire</Link>, ou <Link to="/auxiliaire-veterinaire" style={{ color: "#00acc1" }}>vétérinaire</Link> et devenez immédiatement opérationnels en développant vos connaissances et vos facultés d’adaptation et d’initiative.
                  </h5>
                </span>
              }
              icon={SchoolIcon}
              iconColor="info"
            />
          </GridItem>
          <GridItem md={4} sm={4}>
            <InfoArea
              title="Accessibilité"
              description={
                <span>
                  <h5>
                  Nos formations sont accessibles à tous. Les locaux du Campus EcoSup Toulon sont accessibles à toute personne en situation de handicap et le parcours de formation peut être adapté au Public en Situation de Handicap. Notre référent Handicap (Olivier DUFOUR) est à votre disposition et procèdera à l’adaptation des cursus, des méthodes et des outils pédagogiques pour répondre à chaque situation particulière. 
                  </h5>
                </span>
              }
              icon={AccessibleIcon}
              iconColor="info"
            />
          </GridItem>
        </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
