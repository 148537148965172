import React from "react";

import { useHistory} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import InputIcon from '@material-ui/icons/Input';
// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerDashboardLinksStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {

  const history = useHistory();

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem('jwt');
    console.log('localstorage'+localStorage);
    history.push("/");
    window.location.reload();
  };

  const classes = useStyles();

  return (
    <div>
      <div className={classes.manager}>
        <Button
          href="/"
          color="transparent"
          target="_self"
          className={classes.navLink}
          onClick={logout}
        >
          <InputIcon className={classes.icons} /> Déconnection
        </Button>
      </div>
    </div>
  );
}
