import {
  main,
  mainRaised,
  container,
  section,
  grayColor,
  title,
  whiteColor,
  hexToRgb,
  description,
  coloredShadow,
  esadColor,
  vidalColor,
  supvetoColor,
  aepeColor,
} from "assets/jss/material-kit-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const descriptionStyle = {
  coloredShadow,
  ...customCheckboxRadioSwitch,
  ...footerStyle,
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative"
    }
  },
  main: {
    ...main
    /*overflow: "hidden"*/
  },
  mainRaised,
  parallax: {
    height: "50vh",
    overflow: "hidden"
  },
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
  },
  titleQualite: {
    ...title,
    paddingBottom: "10px",
    paddingTop: "50px",
  },
  titleTop: {
    ...title,
    color: whiteColor
  },
  citytitle : {
    color: grayColor[1],
    textDecoration: "none",
    fontWeight: "700",
    marginTop: "5px",
    marginBottom: "5px",
    minHeight: "32px",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`
  },
  section: {
    ...section,
    padding: "70px 0px",
    "& h4$description": {
      fontSize: "1.5em"
    },
    textAlign: "center",
  },
  features: {
    textAlign: "center !important",
    paddingTop: "30px",
    paddingBottom: "0px",
    "& p": {
      fontSize: "16px",
      lineHeight: "1.6em"
    }
  },
  cardTitleWhite: {
    "&, & a": {
      ...title,
      marginTop: ".625rem",
      marginBottom: "0",
      minHeight: "auto",
      color: whiteColor + " !important"
    }
  },
  cardDescriptionWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)"
  },
  cardDescription: {
    ...description
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
  title2: {
    ...title,
  },
  description: {
    color: grayColor[0],
  },
  descriptionJustify: {
    textAlign: "justify",
    paddingLeft: "40px",
    paddingRight: "40px",
    color: grayColor[0],
  },
  descriptionJustifyQualite: {
    textAlign: "center",
    paddingLeft: "0px",
    paddingRight: "0px",
    color: grayColor[0],
  },
  titleJustifyQualiopi: {
    textAlign: "justify",
    color: grayColor[9],
  },
  descriptionJustifyQualiopi: {
    textAlign: "justify",
    color: grayColor[3],
  },
  descriptionEsad: {
    textAlign: "center",
    color: esadColor[0],
  },
  descriptionVidal: {
    textAlign: "center",
    color: vidalColor[0],
  },
  descriptionSupveto: {
    textAlign: "center",
    color: supvetoColor[0],
  },
  descriptionAepe: {
    textAlign: "center",
    color: aepeColor[0],
  },
};

export default descriptionStyle;
