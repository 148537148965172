import React from "react";
import { useState, useEffect } from "react";
import { useHistory} from "react-router-dom";
import axios from "axios";
// react plugin for creating charts
// @material-ui/core
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";

import CustomInput from "components/CustomInput/CustomInput.js";
import {DropzoneArea} from 'material-ui-dropzone';
import Table from "components/Table/Table.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Button from "components/CustomButtons/Button.js";
import AddIcon from '@material-ui/icons/Add';

import styles from "assets/jss/material-kit-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function BlogPage() {

  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ titre: "", theme: "", auteur:"", texte:"", image:""});
  const history = useHistory();

  useEffect(() => {
    let userjwt = localStorage.getItem('jwt');
    console.log(userjwt);
    if(userjwt!=null){
      retrieveBlogs(userjwt)
    }else{
      console.log('User not logged!!!');
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[history]);

  async function retrieveBlogs(userjwt){
    axios.get(`${global.API_URL}/articles`, {
      headers: {
        Authorization:
        `Bearer ${userjwt}`,
      },
    }).then(res => {
      console.log(res.data);
      let listBlogs = [];
      for (let [index,blog] of res.data.entries()) {
        let tmpBlog = [`${res.data[index].titre}`,`${blog.theme}`,`${blog.auteur}`,`${blog.texte}`,
          <Button justIcon size="sm" simple color="danger" onClick={() => deleteEntry(blog.id)}>
            <DeleteOutlineIcon/>
          </Button>];
        listBlogs.push(tmpBlog);
      }
      setBlogs(listBlogs);
    });
  }

  const deleteEntry = value => {
    let userjwt = localStorage.getItem('jwt');
    const apiUrl = `${global.API_URL}/articles/${value}`;
    fetch(apiUrl, {
      method: "DELETE",
      headers: userjwt && { Authorization: `Bearer ${userjwt}` }})
      .then(function(response){
        retrieveBlogs(userjwt);
      }).catch(function(response){
         console.log('Failed to delete project');
      });
  }

  function uploadBlog()
  {
    let userjwt = localStorage.getItem('jwt');
    if(userjwt!=null){
      setIsLoading(true);
      const formData = new FormData();
      console.log(data.image);
      formData.append('file', data.image);
      formData.append('upload_preset', `${global.cloudinary_preset}`);
      axios
       .post(`${global.cloudinary_url}`, formData)
       .then(res => {
         console.log(res);
         let fileId = `${res.data.secure_url}`;
         console.log(fileId);
         const dataForm = new FormData();
         dataForm.append('data', JSON.stringify({
              titre: `${data.titre}`,
              theme: `${data.theme}`,
              auteur: `${data.auteur}`,
              texte: `${data.texte}`,
              image: `${fileId}`
            }))

         axios.post(`${global.API_URL}/articles`,dataForm, {
           headers: {
             Authorization:`Bearer ${userjwt}`,
           },
         }).then(function(response){
           console.log('Success!');
           setIsLoading(false);
           retrieveBlogs(userjwt);
         })
         .catch(function(){
           console.log('Fail!');
         });
       })
       .catch(err => {
         console.log(err);
       });
    }
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Ajouter un nouvel article</h4>
          </CardHeader>
          <CardBody>
            <GridContainer direction="row"
              justify="center"
              alignItems="center">
              <GridItem xs={12} sm={8} md={8}>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={6}>
                    <CustomInput
                      labelText="Titre"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setData({ ...data, titre: e.target.value }),
                        required: true}}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Thème"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setData({ ...data, theme: e.target.value }),
                        required: true}}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={3}>
                    <CustomInput
                      labelText="Auteur"
                      id="float"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: (e) => setData({ ...data, auteur: e.target.value }),
                        required: true}}
                    />
                  </GridItem>
                </GridContainer>
                <CustomInput
                  labelText="Texte"
                  id="float"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    multiline: true,
                    rows: 5,
                    onChange: (e) => setData({ ...data, texte: e.target.value }),
                    required: true}}
                />
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <DropzoneArea dropzoneClass={classes.drop}
                dropzoneText={"Choisir une image"}
                showPreviews={false}
                showPreviewsInDropzone={true}
                useChipsForPreview
                acceptedFiles={['image/*']}
                filesLimit={1}
                maxFileSize={5000000}
                onChange={(files) => {
                  setData({ ...data, image: files[0] });
                }}/>
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <div className={classes.textCenter}>
                <Button color="info" round onClick={uploadBlog} disabled={isLoading}>
                  <AddIcon/> Ajouter
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <br/>
        <Card>
          <CardHeader color="info">
            <h4 className={classes.cardTitleWhite}>Liste des articles publiés</h4>
          </CardHeader>
          <CardBody>
          <Table
            striped
            tableHead={[
              "Titre",
              "Thème",
              "Auteur",
              "Texte",
              "Action"
            ]}
            tableData={blogs}
            customCellClasses={[
              classes.textLeft,
              classes.textLeft,
              classes.textLeft
            ]}
            customClassesForCells={[0, 1, 2]}
            customHeadCellClasses={[
              classes.textLeft,
              classes.textLeft,
              classes.textLeft
            ]}
            customHeadClassesForCells={[0, 1, 2]}
          />
        </CardBody>
      </Card>
    </GridItem>
    </GridContainer>
  );
}
