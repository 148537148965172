import React from "react";
import { Link } from "react-router-dom";
// core components
import Footer from "components/Footer/Footer.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "components/CustomButtons/Button.js";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/presentationStyle.js";
//import presentationStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const useStyles = makeStyles(presentationStyle);

export default function SectionDescription({ date }) {
  const classes = useStyles();

  function openReglement(){
    window.open(`${global.url_reglement}`);
  }

  function openRegistre(){
    window.open(`${global.url_registre}`);
  }

  function openCGV(){
    window.open(`${global.url_cgv}`);
  }

  return (
    <Footer
      theme="transparent"
      content={
        <div>
          {date ? <div
            className={classNames(classes.pullCenter, classes.copyRight)}
          >
            &copy; {" "}
            Ecosup Toulon.
            {" "}
            Tous droits réservés. Dernière mise à jour: {date}
          </div> :
          <div
          className={classNames(classes.pullCenter, classes.copyRight)}
          >
            &copy; {" "}
            Ecosup Toulon.
            {" "}
            Tous droits réservés. 
          </div>}
        </div>
      }
    >
    <div className={classes.footer}>
      {/* <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={12} sm={3} md={3}>
        <Link
            to="/qualiopi"
            className={classes.block}
          >
          <img
            src={logo_qualiopi}
            className={classNames(
              classes.img,
              classes.imgRaised
            )}
            alt="..."
          />
          <p className={classes.captionlogo}>Cliquez sur le logo pour plus d'informations</p>
        </Link>
        </GridItem> 
        <GridItem xs={12} sm={12} md={12}>*/}
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Button simple onClick={openReglement}
                className={classes.block}
              >
                Règlement intérieur
              </Button>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Button simple onClick={openRegistre}
                className={classes.block}
              >
                Fiche d’accessibilité
              </Button>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Button simple onClick={openCGV}
                className={classes.block}
              >
                CGV
              </Button>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/contact"
                className={classes.block}
              >
                Contact
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/login"
                className={classes.block}
              >
                Administration
              </Link>
            </ListItem>
            {/* <ListItem className={classes.inlineBlock}>
              <Link
                to="/qualite-formation"
                className={classes.block}
              >
                Indicateurs de qualité de la formation continue
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/qualite-apprentissage"
                className={classes.block}
              >
                Indicateurs de qualité de la formation en apprentissage
              </Link>
            </ListItem> */}
          </List>
        {/* </GridItem>
      </GridContainer> */}
    </div>
    </Footer>
  );
}
