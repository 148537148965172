global.logo_ecosup='assets/img/ecosup.png'
//global.API_URL='https://ecosupbackend.herokuapp.com'
global.API_URL='https://ecosupbackend.herokuapp.com'

global.recaptcha_key=''

global.phone_olivier='04.94.22.59.29'

global.cloudinary_url='https://api.cloudinary.com/v1_1/ecosup/image/upload'
global.cloudinary_cloudname='ecosup'
global.cloudinary_preset='ktgfr93k'

global.url_plaquette_vidal='https://ecosup-data.s3.eu-west-2.amazonaws.com/documentation_vidal.pdf'
global.url_plaquette_supveto='https://ecosup-data.s3.eu-west-2.amazonaws.com/documentation_supveto_2023.pdf'
global.url_plaquette_esad='https://ecosup-data.s3.eu-west-2.amazonaws.com/documentation_esad_2021.pdf'
global.url_plaquette_aepe='https://ecosup-data.s3.eu-west-2.amazonaws.com/documentation_aepe_2023.pdf'
global.url_plaquette_aj='https://ecosup-data.s3.eu-west-2.amazonaws.com/documentation_aj_2024.pdf'

global.url_dossier_vidal='https://ecosup-data.s3.eu-west-2.amazonaws.com/dossier_inscription_vidal.pdf'
global.url_dossier_supveto='https://ecosup-data.s3.eu-west-2.amazonaws.com/dossier_inscription_supveto.pdf'
global.url_dossier_esad='https://ecosup-data.s3.eu-west-2.amazonaws.com/dossier_inscription_esad.pdf'
global.url_dossier_aepe='https://ecosup-data.s3.eu-west-2.amazonaws.com/dossier_inscription_aepe.pdf'
global.url_dossier_aj='https://ecosup-data.s3.eu-west-2.amazonaws.com/dossier_inscription_aj.pdf'

global.url_reglement='https://ecosup-data.s3.eu-west-2.amazonaws.com/reglement_interieur.pdf'
global.url_registre='https://ecosup-data.s3.eu-west-2.amazonaws.com/Guide+aide+registre+public+accessibilit%C3%A9.pdf'
global.url_cgv='https://ecosup-data.s3.eu-west-2.amazonaws.com/CGV.docx'
