import React from "react";
import { Link } from "react-router-dom";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import card1 from "assets/img/assistante_full.jpg";
import card2 from "assets/img/secretaire_full.jpg";
import card3 from "assets/img/veto_full.jpg";
import card4 from "assets/img/aepe_full.jpg";
import card5 from "assets/img/juridique_card.jpg";

import logo_esad from "assets/img/logo/esad4.png";
import logo_vidal_formation from "assets/img/logo/vidal5.png";
import logo_supveto from "assets/img/logo/supveto4.png";
import logo_aepe from "assets/img/logo/vidal6.png";
import logo_juridique from "assets/img/logo/vidal8.png";


import descriptionStyle from "assets/jss/material-kit-pro-react/views/echosup/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();

  return (
    <div className={classes.sectionTop}>
      <div className={classes.container}>
        <div className={classes.features}>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem md={4} sm={6}>
              <Card
                backgroundEsad
                style={{ backgroundImage: `url(${card1})` }}
                className={classes.cardLogo}
              >
              <CardHeader image>
                <GridContainer direction="row" justify="center" alignItems="center">
                  <GridItem xs={7} sm={7} md={7}>
                <img src={logo_esad} alt="logo esad" />
                </GridItem>
              </GridContainer>
              </CardHeader>
              <CardBody background>
                <h3 className={classes.cardTitleWhite}>
                    Assistant(e) Dentaire
                </h3>
                <h5 className={classes.cardDescriptionWhite}>
                  En cabinet ou en clinique, devenez le principal bras droit du dentiste.
                </h5>
                <br/>
                <div
                  className={
                    classes.stats + " " + classes.justifyContentCenter
                  }
                >
                <Link to={{ pathname: "https://esad-dentaire.com/" }} target="_blank">
                <Button color="white" round>
                  En savoir plus
                </Button>
                </Link>
                </div>
              </CardBody>
            </Card>
            </GridItem>
            <GridItem md={4} sm={6}>
              <Card
                backgroundVidal
                style={{ backgroundImage: `url(${card2})` }}
                className={classes.cardLogo}
              >
              <CardHeader image>
                <GridContainer direction="row" justify="center" alignItems="center">
                <GridItem xs={7} sm={7} md={7}>
                <img src={logo_vidal_formation} alt="logo vidal" />
                </GridItem>
              </GridContainer>
              </CardHeader>
              <CardBody background>
                <h3 className={classes.cardTitleWhite}>
                    Secrétaire médical(e)
                </h3>
                <h5 className={classes.cardDescriptionWhite}>
                  Accompagnez les patients et les professionnels du monde médical
                </h5>
                <br/>
                <div
                  className={
                    classes.stats + " " + classes.justifyContentCenter
                  }
                >
                <Link to={{ pathname: "https://vidal-formation.net/formation-en-alternance/" }} target="_blank">
                <Button color="white" round>
                  En savoir plus
                </Button>
                </Link>
                </div>
              </CardBody>
            </Card>
            </GridItem>
            <GridItem md={4} sm={6}>
              <Card
                backgroundSupveto
                style={{ backgroundImage: `url(${card3})` }}
                className={classes.cardLogo}
              >
              <CardHeader image>
                <GridContainer direction="row" justify="center" alignItems="center">
                <GridItem xs={7} sm={7} md={7}>
                <img src={logo_supveto} alt="logo esad" />
                </GridItem>
              </GridContainer>
              </CardHeader>
              <CardBody background>
                <h3 className={classes.cardTitleWhite}>
                    Auxiliaire de service vétérinaire
                </h3>
                <h5 className={classes.cardDescriptionWhite}>
                  Vous êtes passionné(e) par les animaux ? Faites de votre passion un métier.
                </h5>
                <br/>
                <div
                  className={
                    classes.stats + " " + classes.justifyContentCenter
                  }
                >
                <Link to="/auxiliaire-veterinaire" >
                <Button color="white" round>
                  En savoir plus
                </Button>
                </Link>
                </div>
              </CardBody>
            </Card>
            </GridItem>
            <GridItem md={4} sm={6}>
              <Card
                backgroundAepe
                style={{ backgroundImage: `url(${card4})` }}
                className={classes.cardLogo}
              >
              <CardHeader image>
                <GridContainer direction="row" justify="center" alignItems="center">
                <GridItem xs={7} sm={7} md={7}>
                <img src={logo_aepe} alt="logo esad" />
                </GridItem>
              </GridContainer>
              </CardHeader>
              <CardBody background>
                <h3 className={classes.cardTitleWhite}>
                Accompagnant(e) éducatif petite enfance
                </h3>
                <h5 className={classes.cardDescriptionWhite}>
                  Vous désirez contribuer à l’éveil et la socialisation des jeunes enfants.
                </h5>
                <br/>
                <div
                  className={
                    classes.stats + " " + classes.justifyContentCenter
                  }
                >
                <Link to="/aepe" >
                <Button color="white" round>
                  En savoir plus
                </Button>
                </Link>
                </div>
              </CardBody>
            </Card>
            </GridItem>
            <GridItem md={4} sm={6}>
              <Card
                backgroundJuridique
                style={{ backgroundImage: `url(${card5})` }}
                className={classes.cardLogo}
              >
              <CardHeader image>
                <GridContainer direction="row" justify="center" alignItems="center">
                <GridItem xs={7} sm={7} md={7}>
                <img src={logo_juridique} alt="logo esad" />
                </GridItem>
              </GridContainer>
              </CardHeader>
              <CardBody background>
                <h3 className={classes.cardTitleWhite}>
                Assistant(e) juridique
                </h3>
                <h5 className={classes.cardDescriptionWhite}>
                  A partir d'Octobre 2024
                </h5>
                <br/>
                <div
                  className={
                    classes.stats + " " + classes.justifyContentCenter
                  }
                >
                <Link to="/juridique" >
                <Button color="white" round>
                  En savoir plus
                </Button>
                </Link>
                </div>
              </CardBody>
            </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      </div>
  );
}
