/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import GetAppIcon from '@material-ui/icons/GetApp';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import logo from "assets/img/logo/supveto.jpg";

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/AuxiliaireVetoStyle.js";

import logo_ecosup from "assets/img/ecosup.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(presentationStyle);

export default function AuxiliaireVetoPage() {
  React.useEffect(() => {
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  retrieveSessions();
},[]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalDownload, setModalDownload] = React.useState(false);
  const [data, setData] = useState({ nom: "", prenom: "", email:"", phone:""});
  const [errors, setErrors] = useState({});

  function handleValidation(){
    let formIsValid = true;
    let errors = {};

    //Name
    let name = `${data.nom}`;
    if(name.trim() == ""){
       formIsValid = false;
       errors["name"] = "*Veuillez entrer un nom";
    }else{
      if(typeof name !== "undefined"){
         if(!name.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["name"] = "*Le nom doit comporter uniquement des caratères";
         }
      }
    }

    //Prenom
    let prenom = `${data.prenom}`;
    if(prenom.trim() == ""){
       formIsValid = false;
       errors["prenom"] = "*Veuillez entrer un prénom";
    }else{
      if(typeof prenom !== "undefined"){
         if(!prenom.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["prenom"] = "*Le prénom doit comporter uniquement des caratères";
         }
      }
    }

    //Email
    let email = `${data.email}`;
    if(email.trim() == ""){
       formIsValid = false;
       errors["email"] = "*Veuillez entrer une adresse email";
    }else{
      if(typeof email !== "undefined"){
         let lastAtPos = email.lastIndexOf('@');
         let lastDotPos = email.lastIndexOf('.');

         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "*Veuillez entrer une adresse email valide";
          }
     }
    }

    //Phone
    let phone = `${data.phone}`;
    if(phone.trim() == ""){
       formIsValid = false;
       errors["phone"] = "*Veuillez entrer un numéro de téléphone";
    }else{
      if(typeof phone !== "undefined"){
         if(!phone.match(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/)){
            formIsValid = false;
            errors["phone"] = "*Veuillez entrer un numéro de téléphone valide";
         }
      }
    }

    if(!checkbox){
      formIsValid = false;
      errors["checkbox"] = "*Veuillez acceptez l'enregistrement de vos informations";
    }

   setErrors(errors);
   console.log(errors);
   return formIsValid;
  }

  const [checkbox, setCheckbox] = useState(false);
  const handleCheckbox = (event) => {
    errors["checkbox"]="";
    setCheckbox(event.target.checked);
  };

  function download() {
    let isValid = handleValidation();
    if(isValid){
      window.open(`${global.url_plaquette_supveto}`);
      setIsLoading(true);
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           nom: `${data.nom}`,
           prenom: `${data.prenom}`,
           email: `${data.email}`,
           phone: `${data.phone}`,
           formation: 2
         }))

      axios.post(`${global.API_URL}/downloads`,dataForm
      ).then(function(response){
      console.log('Success!');
      setModalDownload(false);
      setIsLoading(false);
    })
    .catch(function(){
      console.log('Fail!');
    });
    }
  }

  const [sessions, setSessions] = useState([]);
  const [inscriptionEnabled, setInscriptionEnabled] = useState(false);
  function retrieveSessions()
  {
    let userjwt = localStorage.getItem('jwt');
    axios.get(`${global.API_URL}/session-informations`)
    .then(res => {

      let listSessions = [];
      for (let [index,session] of res.data.entries()) {
        console.log(session);

        const isFound = session.formations.some(element => {
          if (element.id === 4) {
            return true;
          }
        });

        if(isFound){
          let date = moment(session.date).format('DD/MM/yyyy');
          let time = moment(session.date).format('HH:mm');
          let s={text:date+" "+time,id:session.id};
          listSessions.push(s);
        }
      }
      if(listSessions.length>0)setInscriptionEnabled(true);
      setSessions(listSessions);
    });
  }

  const [sessionSelect, setSessionSelect] = React.useState("");
  const handleSessionSelect = event => {
    let code = event.target.value;
    setSessionSelect(event.target.value);
    console.log("Session : "+code);
  }

  const [modalInscription, setModalInscription] = React.useState(false);
  function registerRDV(){
    let isValid = handleValidation();
    if(isValid){
      setIsLoading(true);
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           nom: `${data.nom}`,
           prenom: `${data.prenom}`,
           email: `${data.email}`,
           phone: `${data.phone}`,
           session_information: sessionSelect
         }))

      axios.post(`${global.API_URL}/rdv-sessions`,dataForm
      ).then(function(response){
      console.log('Success!');
      setModalInscription(false);
      setSuccessRegister(true);
      setIsLoading(false);
    })
    .catch(function(){
      console.log('Fail!');
    });
    }
  }

  const [successRegister, setSuccessRegister] = useState(false);
  const handleCloseRegister = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessRegister(false);
  };

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/structure-animaliere.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h2 className={classes.title}>
                      Bachelor Chargé(e) de Gestion et de Management en Structure Animalière
                    </h2>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={8} md={8}>
              <h3 className={classes.cardTitle}>Prérequis</h3>
              <h5 className={classes.description}>

Certification professionnelle d’Auxiliaire de Service Vétérinaire ou autres certifications équivalentes + une de nos spécialisations ou « Validation des Acquis »​</h5>
              <h3 className={classes.cardTitle}>Description du bachelor</h3>
              <h5 className={classes.description}>

Le marché des animaux de compagnie représente un chiffre d’affaires de près de 5 milliards d’Euros. En 10 ans, les ventes ont progressé de 48%. Plus de la moitié des Français déclarent posséder un animal et 18% de ceux qui n’en ont pas pensent en acquérir à l’avenir. Les 3 espèces les plus représentées sont sans surprises le chat avec 14,2 millions, le chien avec 7,6 millions et les oiseaux d’ornements avec 4,7 millions. À cela, il faut ajouter les chevaux, les N.A.C et bien sûr l’ensemble des cheptels d’élevage, 2ème plus grand d’Europe. Les retombées commerciales sont visibles sur l’ensemble du secteur et des entreprises gravitant autour, principalement dans les animaleries et les structures vétérinaires.
Les animaleries ont besoin de personnels formés à la santé animale et aux techniques de la gestion de la clientèle et du management d’équipe.
Les centres vétérinaires deviennent de plus en plus grands et structurés en réseaux. Les compétences managériales, de communication et organisationnelles sont devenus une des priorités des créateurs de cliniques vétérinaires.
Les compétences évolutives nécessaires au fil d’une carrière imposent des bases solides de connaissances dans divers domaines, afin d’évoluer, de s’adapter et même potentiellement de se réorienter.</h5>
              <h3 className={classes.cardTitle}>Durée du cursus</h3>
              <h5 className={classes.description}>16 mois</h5>
              <h3 className={classes.cardTitle}>Programme de Formation</h3>
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    <h5 className={classes.description}>✓ Expertise animale</h5>
                    <h5 className={classes.description}>✓ Gestion et contrôle budgetaire</h5>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <h5 className={classes.description}>✓ Management d'équipe</h5>
                    <h5 className={classes.description}>✓ Management de projet</h5>
                  </GridItem>
                </GridContainer>
              <p className={classes.description}></p>
              <h3 className={classes.cardTitle}>Voie d’accès au bachelor</h3>
                <h5 className={classes.description}>Deux parcours antérieurs sont principalement ciblés :</h5>
                <h5 className={classes.description}>• <b>Titulaire ASV :</b>  Vous avez acquis une expertise dans le domaine de l’assistanat et de la santé animale. </h5>
                <h5 className={classes.description}>• <b>Titulaire d’un BTS agricole option vente des animaux de compagnie :</b> Vous avez acquis une expertise technique dans le secteur du marché animalier.</h5>
                <h5 className={classes.description}>Vous allez étendre votre palette de compétences et entrer dans une approche plus managériale de votre carrière.</h5>
              <h3 className={classes.cardTitle}>Validation</h3>
              <h5 className={classes.description}>Chargé de Gestion et Management, titre certifié de niveau 6, enregistré au RNCP n° 34734 le 29/06/2020, délivré par FORMATIVE – Code NSF 310 m, paru du JO du 19/07/2017 délivré par Formatives – <a target="_blank" href="https://www.formatives.fr">www.formatives.fr</a></h5>
              <h5 className={classes.description}>Arrêté du 30 juin 2020 publié au Journal Officiel portant enregistrement au répertoire national des certifications professionnelles. </h5>
              <h5 className={classes.description}>Enregistrement pour cinq ans, au niveau 6, sous l’intitulé “chargé de gestion et management ». code RNCP 34734</h5>
          <h3 className={classes.cardTitle}>Exemples des métiers accessibles</h3>
                <GridContainer>
                  <GridItem xs={12} sm={7} md={7}>
                    <h5 className={classes.description}>✓ Chef d’équipe ASV</h5>
                    <h5 className={classes.description}>✓ Attaché.e de direction d’une structure animalière</h5>
                    <h5 className={classes.description}>✓ Responsable business Unit en cabinet vétérinaire</h5>
                  </GridItem>
                  <GridItem xs={12} sm={5} md={5}>
                    <h5 className={classes.description}>✓ ASV Responsable de gestion et optimisation</h5>
                    <h5 className={classes.description}>✓ ASV Responsable des ventes</h5>
                    <h5 className={classes.description}>✓ ASV Chargé.e de communication, de promotion et de développement</h5>
                  </GridItem>
                </GridContainer>
              </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <div className={classes.buttonCenter}>
                  <Button color="supvetobachelor" round onClick={() => {setModalDownload(true)}}>
                    <GetAppIcon/>Télécharger la documentation
                  </Button>
                  </div>
                  <Card color="supvetobachelor">
                    <CardBody color>
                      <h4 className={classes.cardTitleWhite}>
                        Prochaines journées portes ouvertes
                      </h4>
                      <br/>
                      <div
                        className={
                          classes.stats + " " + classes.justifyContentCenter
                        }
                      >
                      {inscriptionEnabled ?
                      <Button color="white" round onClick={() => {setModalInscription(true)}}>
                        Je m'inscris
                      </Button> : <h6>pas de session prévue pour l'instant</h6>}
                      </div>
                    </CardBody>
                  </Card>
                  {/*<h3 className={classes.cardTitle}>Dispositif de formation</h3>
                  <h5 className={classes.description}>30 semaines de stage sur l’ensemble de la formation</h5>
                  <h5 className={classes.description}>Immersion en milieu professionnel avec 2 stages de 26 semaines au total sur 2 périodes de longue durée</h5>*/}
                </GridItem>
              </GridContainer>
          </div>
        </div>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalDownload}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalDownload(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          maxWidth="md"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Téléchargez la documentation</h4>
          <p className={classes.description}>Veuillez remplir le formulaire pour accéder au téléchargement</p>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Nom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Prénom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
            </GridItem>
          </GridContainer>
              <CustomInput
                labelText="Téléphone"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
              <CustomInput
                labelText="Email"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={handleCheckbox}
                  />
                }
                label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
              />
              <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
              <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {download()}} color="supvetobachelor" round>
              {isLoading ? "Veuillez patienter ... " : "Téléchargement"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalInscription}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalInscription(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          maxWidth="md"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Réunion d'informations à Toulon</h4>
          <p className={classes.features}>Lieu de la réunion : <b>Ecosup-Toulon</b></p>
          <p className={classes.features}>177 Boulevard Charles Barnier, 83000 Toulon</p>
          <p className={classes.features}>(accés depuis l'impasse Barnier)</p>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Session
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={sessionSelect}
                onChange={handleSessionSelect}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
                >
                {sessions.map((session,index) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={session.id}
                    key={index}
                  >
                    {session.text}
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p style={{color: "red",fontSize: "12px"}}>{errors["session"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Nom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Prénom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
            </GridItem>
          </GridContainer>
              <CustomInput
                labelText="Téléphone"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
              <CustomInput
                labelText="Email"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={handleCheckbox}
                  />
                }
                label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
              />
              <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
              <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {registerRDV()}} color="supvetobachelor" round>
              {isLoading ? "Veuillez patienter ... " : "Inscription"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar open={successRegister} autoHideDuration={5000} onClose={handleCloseRegister}>
        <Alert onClose={handleCloseRegister} severity="success">
          Votre inscription a bien été enregistrée!
        </Alert>
      </Snackbar>
      <SectionFooter/>
    </div>
  );
}
