
/*eslint-disable*/
import React from "react";
// @material-ui/core components
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Chart from "react-apexcharts";

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ReseauStyle.js";

import SectionFooter from "views/footer/PageFooter.js"

const useStyles = makeStyles(presentationStyle);

const esadColor="#00abd9";
const vidalColor="#2b5bae";
const supvetoColor="#008a53";
const aepeColor="#FF5E4D";

const plot_opts={
    radialBar: {
        startAngle: -90,
        endAngle: 90,
      track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: '#999',
            opacity: 1,
            blur: 2
          }
      },
      dataLabels: {
        name: {
            show: false,
          },
          value: {
            offsetY: -5,
            fontSize: '30px',
            color: '#777',
          }
        }
    },
  };

const options_secmed_acc= {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: plot_opts,
    colors: [vidalColor],
    labels: ['Secrétaire médicale'],
  };
const series_secmed_acc = [100];
const series_secmed_sat = [100];

const options_assdent_acc= {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: plot_opts,
    colors: [esadColor],
    labels: ['Assitante dentaire'],
  };
const series_assdent_acc = [100];
const series_assdent_sat = [100];

const options_supveto_acc= {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: plot_opts,
    colors: [supvetoColor],
    labels: ['ASV Auxiliaire de Service Vétérinaire'],
  };
const series_supveto_acc = [100];
const series_supveto_sat = [78.6];


export default function QualitePage() {

const classes = useStyles();

return (
  <div>
    <Header
      links={<HeaderLinks dropdownHoverColor="info" />}
      fixed
      color="transparent"
      changeColorOnScroll={{
        height: 50,
        color: "white"
      }}
    />
    <Parallax
      image={require("assets/img/qualite.jpg")}
      className={classes.parallax}
      filter="dark"
    >
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <div className={classes.brand}>
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12} sm={8} md={8}>
                  <h1 className={classes.titleTop}>
                    Indicateurs de Qualité des Formations en Apprentissage
                  </h1>
                </GridItem>
              </GridContainer>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Parallax>
    <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
          <p className={classes.descriptionJustifyQualite}>Ces pourcentages ont été calculés à partir des effectifs des étudiants ayant effectué leur formation en apprentissage.</p>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleQualite}>Taux de Réussite</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionVidal}><b>Secrétaire Médicale</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés fin Mai 2024.</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionEsad}><b>Assitante Dentaire</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés fin Décembre 2023.</p>
            </GridItem>  
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionAepe}><b>Accompagnant(e) éducatif petite enfance</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés en Septembre 2024.</p>
            </GridItem>  
          </GridContainer>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleQualite}>Taux de Poursuite d'Etude et d'Insertion à 6 mois pour 2023</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionVidal}><b>Secrétaire Médicale</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés fin Mai 2024.</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionEsad}><b>Assitante Dentaire</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés fin Juin 2024.</p>
            </GridItem>  
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionAepe}><b>Accompagnant(e) éducatif petite enfance</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés en Septembre 2024.</p>
            </GridItem>  
          </GridContainer>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleQualite}>Taux d'Accomplissement: Année 2022 - 2023</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <Chart options={options_secmed_acc} series={series_secmed_acc} type="radialBar" 
            />
            <h5 className={classes.descriptionVidal}><b>Secrétaire Médicale</b></h5>
            <h5 className={classes.descriptionVidal}>8/8 étudiants</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <Chart options={options_assdent_acc} series={series_assdent_acc} type="radialBar" 
            />
            <h5 className={classes.descriptionEsad}><b>Assitante Dentaire</b></h5>
            <h5 className={classes.descriptionEsad}>8/8 étudiants</h5>
            </GridItem>  
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionAepe}><b>Accompagnant(e) éducatif petite enfance</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés en Septembre 2024.</p>
            </GridItem>      
          </GridContainer>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleQualite}>Taux de Satisfaction Global: Année 2022 - 2023</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <Chart options={options_secmed_acc} series={series_secmed_sat} type="radialBar" 
            />
            <h5 className={classes.descriptionVidal}><b>Secrétaire Médicale</b></h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <Chart options={options_assdent_acc} series={series_assdent_sat} type="radialBar" 
            />
            <h5 className={classes.descriptionEsad}><b>Assitante Dentaire</b></h5>
            </GridItem> 
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionAepe}><b>Accompagnant(e) éducatif petite enfance</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés en Septembre 2024.</p>
            </GridItem>  
          </GridContainer>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleQualite}>Taux d'interruption en cours et reprise de la formation 2022</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <Chart options={options_secmed_acc} series={[0]} type="radialBar" 
            />
            <h5 className={classes.descriptionVidal}><b>Secrétaire Médicale</b></h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <Chart options={options_assdent_acc} series={[0]} type="radialBar" 
            />
            <h5 className={classes.descriptionEsad}><b>Assitante Dentaire</b></h5>
            </GridItem> 
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionAepe}><b>Accompagnant(e) éducatif petite enfance</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés en Septembre 2024.</p>
            </GridItem>  
          </GridContainer>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={12} md={12}>
            <h2 className={classes.titleQualite}>Taux de Rupture - Année 2023</h2>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionVidal}><b>Secrétaire Médicale</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés fin Janvier 2024.</p>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionEsad}><b>Assitante Dentaire</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés fin Janvier 2024.</p>
            </GridItem> 
            <GridItem xs={12} sm={12} md={4}>
            <h5 className={classes.descriptionAepe}><b>Accompagnant(e) éducatif petite enfance</b></h5>
            <p className={classes.descriptionJustifyQualite}>Résultats non connus à ce jour.</p>
            <p className={classes.descriptionJustifyQualite}>Les taux seront affichés en Septembre 2024.</p>
            </GridItem>  
          </GridContainer>
          </div>
        </div>
    </div>
    <SectionFooter/>
 </div>
   );
}