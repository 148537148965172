import {
  container,
  title,
  main,
  whiteColor,
  grayColor,
  mainRaised,
  hexToRgb,
  section
} from "assets/jss/material-kit-pro-react.js";
import footerStyle from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js";

const presentationStyle = {
  ...footerStyle,
  main: {
    ...main
    /*overflow: "hidden"*/
  },
  mainRaised,
  parallax: {
    height: "50vh",
    overflow: "hidden"
  },
  container: {
    ...container,
    zIndex: 1
  },
  title: {
    ...title,
    color: whiteColor
  },
  title2: {
    ...title,
    textAlign: "center"
  },
  description: {
    color: grayColor[0],
    textAlign: "justify",
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  title_sub: {
    ...title,
    textAlign: "left"
  },
  description_sub: {
    color: grayColor[0],
    textAlign: "left"
  },
  section_sub: {
    ...section,
    padding: "10px 0px",
    "& h4$description": {
      fontSize: "1.5em"
    }
  },
  section: {
    ...section,
    padding: "70px 0px",
    "& h4$description": {
      fontSize: "1.5em"
    }
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "4.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative"
    }
  },
  proBadge: {
    position: "relative",
    fontSize: "22px",
    textTransform: "uppercase",
    fontWeight: "700",
    right: "-10px",
    padding: "10px 18px",
    top: "-30px",
    background: whiteColor,
    borderRadius: "3px",
    color: grayColor[18],
    lineHeight: "22px",
    boxShadow: "0 5px 5px -2px rgba(" + hexToRgb(grayColor[25]) + ",.4)"
  },
  textCenter: {
    textAlign: "center"
  },
  justifyContentCenter: {
    textAlign: "center",
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
  cardTitleWhite: {
    textAlign: "center",
    paddingBottom: "20px",
    "&, & a": {
      ...title,
      marginTop: ".625rem",
      marginBottom: "0",
      minHeight: "auto",
      color: whiteColor + " !important"
    }
  },
  cardDescriptionWhite: {
    textAlign: "center",
    paddingBottom: "20px",
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)"
  },
};

export default presentationStyle;
