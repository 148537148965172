/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import GetAppIcon from '@material-ui/icons/GetApp';
import FolderIcon from '@material-ui/icons/Folder';
import PeopleIcon from '@material-ui/icons/People';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import SchoolIcon from '@material-ui/icons/School';
import ComputerIcon from '@material-ui/icons/Computer';
import TodayIcon from '@material-ui/icons/Today';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AssignmentIcon from '@material-ui/icons/Assignment';
// core components
import InfoArea from "components/InfoArea/InfoArea.js";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/SecretaireMedicaleStyle.js";

import logo_ecosup from "assets/img/ecosup.png";

import imgSecMed from "assets/img/aepe_full.jpg";

import part1 from "assets/img/partenaire_vidal/API.jpg";
import part2 from "assets/img/partenaire_vidal/COL.jpg";
import part3 from "assets/img/partenaire_vidal/GHICL.jpg";
import part4 from "assets/img/partenaire_vidal/MCO1.jpg";
import part5 from "assets/img/partenaire_vidal/rbx.jpg";
import part6 from "assets/img/partenaire_vidal/SA.jpg";

import logo from "assets/img/logo/esad.png";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(presentationStyle);

export default function AepePage() {
  React.useEffect(() => {
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  retrieveSessions();
},[]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalDownload, setModalDownload] = React.useState(false);
  const [data, setData] = useState({ nom: "", prenom: "", email:"", phone:""});
  const [errors, setErrors] = useState({});

  function handleValidation(){
    let formIsValid = true;
    let errors = {};

    //Name
    let name = `${data.nom}`;
    if(name.trim() == ""){
       formIsValid = false;
       errors["name"] = "*Veuillez entrer un nom";
    }else{
      if(typeof name !== "undefined"){
         if(!name.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["name"] = "*Le nom doit comporter uniquement des caratères";
         }
      }
    }

    //Prenom
    let prenom = `${data.prenom}`;
    if(prenom.trim() == ""){
       formIsValid = false;
       errors["prenom"] = "*Veuillez entrer un prénom";
    }else{
      if(typeof prenom !== "undefined"){
         if(!prenom.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["prenom"] = "*Le prénom doit comporter uniquement des caratères";
         }
      }
    }

    //Email
    let email = `${data.email}`;
    if(email.trim() == ""){
       formIsValid = false;
       errors["email"] = "*Veuillez entrer une adresse email";
    }else{
      if(typeof email !== "undefined"){
         let lastAtPos = email.lastIndexOf('@');
         let lastDotPos = email.lastIndexOf('.');

         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "*Veuillez entrer une adresse email valide";
          }
     }
    }

    //Phone
    let phone = `${data.phone}`;
    if(phone.trim() == ""){
       formIsValid = false;
       errors["phone"] = "*Veuillez entrer un numéro de téléphone";
    }else{
      if(typeof phone !== "undefined"){
         if(!phone.match(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/)){
            formIsValid = false;
            errors["phone"] = "*Veuillez entrer un numéro de téléphone valide";
         }
      }
    }

    if(!checkbox){
      formIsValid = false;
      errors["checkbox"] = "*Veuillez acceptez l'enregistrement de vos informations";
    }

   setErrors(errors);
   console.log(errors);
   return formIsValid;
  }

  const [checkbox, setCheckbox] = useState(false);
  const handleCheckbox = (event) => {
    errors["checkbox"]="";
    setCheckbox(event.target.checked);
  };

  function download() {
    let isValid = handleValidation();
    if(isValid){
      window.open(`${global.url_plaquette_aepe}`);
      setIsLoading(true);
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           nom: `${data.nom}`,
           prenom: `${data.prenom}`,
           email: `${data.email}`,
           phone: `${data.phone}`,
           formation: 5
         }))

      axios.post(`${global.API_URL}/downloads`,dataForm
      ).then(function(response){
      console.log('Success!');
      setModalDownload(false);
      setIsLoading(false);
    })
    .catch(function(){
      console.log('Fail!');
    });
    }
  }

  const [sessions, setSessions] = useState([]);
  const [inscriptionEnabled, setInscriptionEnabled] = useState(false);
  function retrieveSessions()
  {
    let userjwt = localStorage.getItem('jwt');
    axios.get(`${global.API_URL}/session-informations`)
    .then(res => {

      let listSessions = [];
      for (let [index,session] of res.data.entries()) {
        console.log(session);

        const isFound = session.formations.some(element => {
          if (element.id === 5) {
            return true;
          }
        });

        if(isFound){
          let date = moment(session.date).format('DD/MM/yyyy');
          let time = moment(session.date).format('HH:mm');
          let s={text:date+" "+time,id:session.id};
          listSessions.push(s);
        }
      }
      if(listSessions.length>0)setInscriptionEnabled(true);
      setSessions(listSessions);
    });
  }

  const [sessionSelect, setSessionSelect] = React.useState("");
  const handleSessionSelect = event => {
    let code = event.target.value;
    setSessionSelect(event.target.value);
    console.log("Session : "+code);
  }

  const [modalInscription, setModalInscription] = React.useState(false);
  function registerRDV(){
    let isValid = handleValidation();
    if(isValid){
      setIsLoading(true);
      const dataForm = new FormData();
      dataForm.append('data', JSON.stringify({
           nom: `${data.nom}`,
           prenom: `${data.prenom}`,
           email: `${data.email}`,
           phone: `${data.phone}`,
           session_information: sessionSelect
         }))

      axios.post(`${global.API_URL}/rdv-sessions`,dataForm
      ).then(function(response){
      console.log('Success!');
      setModalInscription(false);
      setSuccessRegister(true);
      setIsLoading(false);
    })
    .catch(function(){
      console.log('Fail!');
    });
    }
  }

  const [successRegister, setSuccessRegister] = useState(false);
  const handleCloseRegister = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessRegister(false);
  };

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/aepe.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h1 className={classes.title}>
                      Accompagnant(e) éducatif petite enfance
                    </h1>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.section}>
          <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={8} md={8}>
            <h3 className={classes.title2}>Prérequis</h3>
            <div className={classes.features}>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <InfoArea
                    vertical
                    icon={SchoolIcon}
                    description="Avec ou sans diplôme"
                    iconColor="aepe"
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <InfoArea
                    vertical
                    icon={AssignmentIcon}
                    description="Âgé(e) d'au moins de 18 ans"
                    iconColor="aepe"
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <InfoArea
                    vertical
                    icon={PeopleIcon}
                    description="Avoir un projet ou une expérience dans la petite enfance."
                    iconColor="aepe"
                  />
                </GridItem>
              </GridContainer>

            </div>
            <h3 className={classes.cardTitle}>Objectifs</h3>
            <h5 className={classes.description}>Le CAP <b>accompagnant éducatif petite enfance</b> est le premier niveau de diplôme pour exercer dans la petite enfance.
Avec plus de 700 000 naissances en France chaque année, le secteur de la petite enfance ne cesse de créer de la demande de personnes qualifiées.</h5>
<h5 className={classes.description}>Grâce à ce diplôme, le titulaire, professionnel de l’accueil et de la garde des jeunes enfants contribue à l’éveil et la socialisation des enfants âgés des 0 à 6 ans.
Il les aide à acquérir leur autonomie et participe à leur développement affectif et intellectuel. Il réalise des activités de soins quotidiens et assure l’entretien des espaces de vie et des équipements.</h5>
            <h3 className={classes.cardTitle}>Les méthodes pédagogiques</h3>
            <h5 className={classes.description}>La formation est dispensée :</h5>
            <h5 className={classes.description}>✓ En présentiel (en classe sur site) ou à distance (selon les impératifs : confinements, absences…)</h5>
            <h5 className={classes.description}>✓ Par des méthodes dites magistrales ou participatives ou de découvertes</h5>
            <h5 className={classes.description}>✓ Avec la classe entière, par petits groupes ou en individuel.</h5>
            <h3 className={classes.cardTitle}>La procédure de candidature</h3>
            <h5 className={classes.description}>• Je m'inscris à une session d'information.</h5>
            <h5 className={classes.description}>• Je remplis un dossier de candidature, et je le complète avec les pièces justificatives.</h5>
            <h5 className={classes.description}>• Je me rends à une session d'information qui a lieu au centre de formation où un test de positionnement et un entretien individuel seront effectués.</h5>
            <h5 className={classes.description}>• Je reçois ma réponse d'admissibilité dans un délai de 7 jours par mail.</h5>
            <h3 className={classes.cardTitle}>Les modules d'enseignement</h3>
            <h4 className={classes.description}><b>UNITÉ PROFESSIONNELLE 1 :</b></h4>
            <h4 className={classes.description}>Accompagner le développement du jeune enfant</h4>
            <p className={classes.description}>✓ Recueillir les informations, s'informer sur les éléments du contexte et de la situation professionnelle à prendre en compte</p>
            <p className={classes.description}>✓ Adopter une posture professionnelle adaptée</p>
            <p className={classes.description}>✓ Mettre en œuvre les conditions favorables à l'activité libre et à l'expérimentation dans un contexte donné</p>
            <p className={classes.description}>✓ Mettre en œuvre des activités d'éveil en tenant compte de la singularité de l'enfant</p>
            <p className={classes.description}>✓ Réaliser les soins du quotidien et accompagner l'enfant dans ses apprentissages</p>
            <p className={classes.description}>✓ Appliquer les protocoles liés à la santé</p>
            <br/>
            <h4 className={classes.description}><b>UNITÉ PROFESSIONNELLE 2 :</b></h4>
            <h4 className={classes.description}>Exercer son activité en accueil collectif</h4>
            <p className={classes.description}>✓ Etablir une relation privilégiée et sécurisante avec l'enfant</p>
            <p className={classes.description}>✓ Coopérer avec l'ensemble des acteurs concernés dans un but de cohérence, d'adaptation et de continuité de l'accompagnement</p>
            <p className={classes.description}>✓ Assurer une assistance pédagogique au personnel enseignant</p>
            <p className={classes.description}>✓ Assurer des activités de remise en état des matériels et locaux en école maternelle</p>
            <br/>
            <h4 className={classes.description}><b>UNITÉ PROFESSIONNELLE 3 :</b></h4>
            <h4 className={classes.description}>Exercer son activité en accueil individuel</h4>
            <p className={classes.description}>✓ Organiser son action</p>
            <p className={classes.description}>✓ Négocier le cadre de l'accueil (attentes des parents, projet d'accueil, cadre organisationnel et conventionnel)</p>
            <p className={classes.description}>✓ Assurer les opérations d'entretien du logement et des espaces réservés à l'enfant</p>
            <p className={classes.description}>✓ Elaborer des repas (concevoir, préparer et servir des repas en milieu familial)</p>
            <br/>
            <h4 className={classes.description}><b>ENSEIGNEMENT GÉNÉRAL :</b></h4>
            <p className={classes.description}>✓ Français</p>
            <p className={classes.description}>✓ Histoire et Géographie</p>
            <p className={classes.description}>✓ Mathématiques</p>
            <p className={classes.description}>✓ Sciences physiques et chimiques</p>
            <p className={classes.description}>✓ Epreuve Physique et Sportive (EPS)</p>
            <h3 className={classes.cardTitle}>Modalités d'évaluation des compétences / Certification</h3>
            <h5 className={classes.description}>✓ Une épreuve écrite et orale (U1) sur l’accompagnement du développement du jeune enfant</h5>
            <h5 className={classes.description}>✓ Une épreuve écrite (U2) sur l’exercice de l’activité en accueil collectif</h5>
            <h5 className={classes.description}>✓ Une épreuve orale (U3) sur l’exercice de l’activité en accueil individuel.</h5>
            <h3 className={classes.cardTitle}>Indicateurs de résultats</h3>
            <h5 className={classes.description}>Taux de réussite : 100% (Sur 12 candidates présentées, 12 ont validé la certification)</h5>
            <h5 className={classes.description}>Taux d’insertion à 6 mois : NC (Les 1ères candidates qui ont passé leurs certifications ont été diplômées en juillet 2024. Le taux d’insertion à 6 mois sera communiqué en janvier 2025)</h5>
            <h5 className={classes.description}>Taux d’insertion dans le métier visé : NC</h5>
            <h5 className={classes.description}>Taux d’interruption en cours de formation : 0%</h5>
            <h5 className={classes.description}>Taux de poursuite d’étude : 0%</h5>
            <h5 className={classes.description}>Taux de satisfaction global : 74%</h5>
            <h5 className={classes.description}>Taux global d’accomplissement : 100%</h5>
            <h5 className={classes.description}>Taux d’abandons : 0%</h5>
            <br/>
            <p className={classes.descriptionJob}>Nos chiffres ne sont pas inscrits sur Insert Jeunes et nous ne communiquons pas sur la valeur ajoutée. Seul les établissements publics et les établissements d'enseignements privés sous contrats sont concernés.</p>
            <h3 className={classes.cardTitle}>Perspectives de parcours</h3>
            <h5 className={classes.description}>Vous avez la possibilité de valider un ou des blocs de compétences acquis à vie. Pour toutes équivalences, passerelles et suite de parcours se rapprocher d’Olivier Dufour au 04.94.22.59.29</h5>

            </GridItem>

            <GridItem xs={12} sm={4} md={4}>
              <Card
                backgroundAepe
                style={{ backgroundImage: `url(${imgSecMed})` }}
              >
                <CardBody background>
                  <h3 className={classes.cardTitleWhite}>
                    Vous souhaitez devenir accompagnant(e) éducatif petite enfance?
                  </h3>
                  <br/>
                  <h3 className={classes.cardTitleWhite}>
                    Rendez vous à notre prochaine session d'information
                  </h3>
                  <br/>
                  <br/>
                  <p className={classes.cardDescriptionWhite}>
                    <b>Impasse du Docteur Charles Barnier</b>
                  </p>
                  <p className={classes.cardDescriptionWhite}>
                    <b>83000 Toulon</b>
                  </p>
                  <br/>
                  <br/>
                  {inscriptionEnabled ?
                  <Button color="white" round onClick={() => {setModalInscription(true)}}>
                    Je m'inscris
                  </Button> : <h6>pas de session prévue pour l'instant</h6>}
                  <br/>
                  <br/>
                </CardBody>
              </Card>
              <div className={classes.buttonCenter}>
              <Button color="aepe" round onClick={() => {setModalDownload(true)}}>
                <GetAppIcon/>Télécharger la documentation
              </Button>
              </div>
              <h3 className={classes.cardTitle}>Dispositif de formation</h3>
              <h5 className={classes.description}>Les cours se déroulent de septembre à juin</h5>
              <h5 className={classes.description}>12 mois en alternance</h5>
              <h5 className={classes.description}>450 heures en centre de formation</h5>
              <h3 className={classes.cardTitle}>Equipe Pédagogique</h3>
              <h5 className={classes.description}><b>Olivier DUFOUR</b></h5>
              <p className={classes.descriptionJob}>Directeur - Référent Handicap</p>
              <h5 className={classes.description}><b>Agnès CAPARROS</b></h5>
              <p className={classes.descriptionJob}>Responsable des admissions</p>
              <h3 className={classes.cardTitle}>Type de diplôme</h3>
              <p className={classes.descriptionJob}>Certification professionnelle de niveau 3, numéro RNCP 38565, d’Accompagnant Educatif Petite Enfance délivré par le Ministère de l’Education Nationale et de la Jeunesse enregistrée le 22 janvier 2024 au Répertoire National des Certifications Professionnelles  (<Link to={{ pathname: "https://www.francecompetences.fr/recherche/rncp/38565/" }} target="_blank" style={{ color: "#00acc1" }}>RNCP</Link>) </p>
              <h3 className={classes.cardTitle}>Tarif</h3>
              <p className={classes.description}>Formation en alternance: Les coûts pédagogiques sont pris en charge dans le cadre de l’alternance par les Opérateurs de Compétences (OPCO).</p>
              <h3 className={classes.cardTitle}>Débouchés professionnels</h3>
            <h4 className={classes.description}>Le titulaire du CAP accompagnant petite enfance exerce:</h4>
            <p className={classes.description}> - En école maternelle : CLAE (Centre de Loisirs Associé à l'Ecole)</p>
            <p className={classes.description}> - En AEJE (Etablissement d'Accueil du Jeune Enfant)</p>
            <p className={classes.description}> - En ACM (Accueil Collectif des jeunes Mineurs)</p>
            <p className={classes.description}> - En MAM (Maison d'Assistants Maternels)</p>
            <p className={classes.description}> - Au domicile des parents</p>
            <p className={classes.description}> - A son domicile (en libéral, en crèche familiale)</p>
            </GridItem>
          </GridContainer>
          </div>
        </div>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalDownload}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalDownload(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          maxWidth="md"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Téléchargez la documentation</h4>
          <p className={classes.description}>Veuillez remplir le formulaire pour accéder au téléchargement</p>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Nom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Prénom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
            </GridItem>
          </GridContainer>
              <CustomInput
                labelText="Téléphone"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
              <CustomInput
                labelText="Email"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={handleCheckbox}
                  />
                }
                label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
              />
              <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
              <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {download()}} color="aepe" round>
              {isLoading ? "Veuillez patienter ... " : "Téléchargement"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalInscription}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalInscription(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          maxWidth="md"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Réunion d'informations à Toulon</h4>
          <p className={classes.features}>Lieu de la réunion : <b>Ecosup-Toulon</b></p>
          <p className={classes.features}>177 Boulevard Charles Barnier, 83000 Toulon</p>
          <p className={classes.features}>(accés depuis l'impasse Barnier)</p>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Session
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={sessionSelect}
                onChange={handleSessionSelect}
                inputProps={{
                  name: "simpleSelect",
                  id: "simple-select"
                }}
                >
                {sessions.map((session,index) => (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={session.id}
                    key={index}
                  >
                    {session.text}
                  </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p style={{color: "red",fontSize: "12px"}}>{errors["session"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Nom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                labelText="Prénom"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
            </GridItem>
          </GridContainer>
              <CustomInput
                labelText="Téléphone"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
              <CustomInput
                labelText="Email"
                id="float"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                  required: true}}
              />
            <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
              <FormControlLabel
                control={
                  <Checkbox
                    color="default"
                    onChange={handleCheckbox}
                  />
                }
                label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
              />
              <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
              <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {registerRDV()}} color="aepe" round>
              {isLoading ? "Veuillez patienter ... " : "Inscription"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Snackbar open={successRegister} autoHideDuration={5000} onClose={handleCloseRegister}>
        <Alert onClose={handleCloseRegister} severity="success">
          Votre inscription a bien été enregistrée!
        </Alert>
      </Snackbar>
      <SectionFooter date={"15 Août 2024"}/>
    </div>
  );
}
