import React from "react";
import { useState, useEffect } from "react";
import { useHistory} from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import { DateTime } from "luxon";
// react plugin for creating charts
// @material-ui/core
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Table from "components/Table/Table.js";

import styles from "assets/jss/material-kit-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function MessagePage() {

  const headers = [
    "Nom",
    "Prénom",
    "Email",
    "Téléphone",
    "Texte"
  ];

  const [messages, setMessages] = useState([]);
  const history = useHistory();

  useEffect(() => {
    retrieveMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[history]);

  function retrieveMessages(){
    let userjwt = localStorage.getItem('jwt');
    console.log(userjwt);
    if(userjwt!=null){
      axios.get(`${global.API_URL}/messages?_limit=-1`, {
        headers: {
          Authorization:
          `Bearer ${userjwt}`,
        },
      }).then(res => {
        let listMessages = [];
        for (let [index,message] of res.data.entries()) {
          let datestr_created = DateTime.fromISO(`${res.data[index].created_at}`);
          let date = datestr_created.setLocale('fr').toLocaleString();
          let tmpMessage = [`${message.nom}`,`${message.prenom}`,`${message.email}`,`${message.phone}`,`${message.texte}`,date,
            <Button justIcon size="sm" simple color="danger" onClick={() => deleteEntry(message.id)}>
              <DeleteOutlineIcon/>
            </Button>];
          listMessages.push(tmpMessage);
        }
        listMessages.sort(function (a, b) {
          return b[5].split('/').reverse().join().localeCompare(a[5].split('/').reverse().join());
        });
        setMessages(listMessages);
        console.log(listMessages);
      });
    }else{
      console.log('User not logged!!!');
      history.push('/');
    }
  }

  const deleteEntry = value => {
    let userjwt = localStorage.getItem('jwt');
    const apiUrl = `${global.API_URL}/messages/${value}`;
    fetch(apiUrl, {
      method: "DELETE",
      headers: userjwt && { Authorization: `Bearer ${userjwt}` }})
      .then(function(response){
        retrieveMessages();
      }).catch(function(response){
         console.log('Failed to delete project');
      });
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <GridContainer justify="center" alignItems="center">
            <GridItem xs={12} sm={8} md={8}>
            <h4 className={classes.cardTitleWhite}>Liste des messages reçus</h4>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
            <div className={classes.buttonRight}><CSVLink data={messages} headers={headers}><Button color="white" round>Télécharger le fichier Excel</Button></CSVLink></div>
            </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
          <Table
            striped
            tableHead={[
              "Nom",
              "Prénom",
              "Email",
              "Téléphone",
              "Texte",
              "Date"
            ]}
            tableData={messages}
            customCellClasses={[
              classes.textLeft,
              classes.textLeft,
              classes.textLeft
            ]}
            customClassesForCells={[0, 1, 2]}
            customHeadCellClasses={[
              classes.textLeft,
              classes.textLeft,
              classes.textLeft
            ]}
            customHeadClassesForCells={[0, 1, 2]}
          />
        </CardBody>
      </Card>
    </GridItem>
    </GridContainer>
  );
}
