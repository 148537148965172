import React, { Component } from 'react';
import Datamap from 'datamaps/dist/datamaps.world.min.js';
import d3 from 'd3';
import FranceJson from './France.topo.json';

class ChoroplethMap extends Component {
    componentDidUpdate(prevProps, prevState) {
        this.map.bubbles(this.props.list_data, {
          popupTemplate: function(geo, data) {
            return '<div class="hoverinfo">' + data.city + '<br/>' + data.adresse+ '<br/>' +data.phone
          },
          borderWidth: 0,
          borderOpacity: 1,
          borderColor: '#111111',
          highlightOnHover: true,
          highlightFillColor: '#ffd500',
        });
        this.map.svg.selectAll('.datamaps-bubble').on('click', (bubble) => {
                window.location.href = bubble.link;
            });
    }
    componentDidMount() {
        // Datamaps expect data in format:
        // { "USA": { "fillColor": "#42a844", numberOfWhatever: 75},
        //   "FRA": { "fillColor": "#8dc386", numberOfWhatever: 43 } }
        let dataset = {};

        // We need to colorize every country based on "numberOfWhatever"
        // colors should be uniq for every value.
        // For this purpose we create palette(using min/max this.props.data-value)
        let onlyValues = this.props.data.map(function (obj) { return obj[1]; });
        let minValue = Math.min.apply(null, onlyValues),
            maxValue = Math.max.apply(null, onlyValues);

        // create color palette function
        // color can be whatever you wish
        let paletteScale = d3.scale.linear()
            .domain([minValue, maxValue])
            .range(["#EFEFFF", "#02386F"]); // blue color

        // fill dataset in appropriate format
        this.props.data.forEach(function (item) { //
            // item example value ["USA", 70]
            let iso = item[0],
                value = item[1];
            dataset[iso] = { numberOfThings: value, fillColor: paletteScale(value) };
        });

        this.map = new Datamap({
            element: document.getElementById('cloropleth_map'),
            scope: 'france',
            geographyConfig: {
                popupOnHover: true,
                highlightOnHover: false,
                borderColor: '#CCCCCC',
                highlightBorderWidth: 0,
                borderWidth: 0.,
                dataJson: FranceJson,
                popupTemplate: function (geo, data) {
                    return;
                }
            },
            fills: {
                ESAD: '#00abd9',
                VIDAL: '#0088cc',
                SUPVETO: '#008a53',
                AEPE: '#FF5E4D',
                UNKNOWN: 'rgb(0,0,0)',
                defaultFill: '#CCCCCC'
            },
            data: dataset,
            setProjection: function (element) {
                var projection = d3.geo.mercator()
                    .center([15, 46]) // always in [East Latitude, North Longitude]
                    .scale(1350);

                var path = d3.geo.path().projection(projection);
                return { path: path, projection: projection };
            }
        });
        this.map.bubbles(this.props.list_data, {
          popupTemplate: function(geo, data) {
            return '<div class="hoverinfo">' + data.city + '<br/>' + data.adresse+ '<br/>' +data.phone
          },
          borderWidth: 0,
          borderOpacity: 1,
          borderColor: '#111111',
          highlightOnHover: true,
          highlightFillColor: '#008D59',
        });
        this.map.svg.selectAll('.datamaps-bubble').on('click', (bubble) => {
                window.location.href = bubble.link;
            });
    }
    render() {
        return (
            <div id="cloropleth_map" style={{
                height: "100vh",
                width: "100vw",
            }}></div>
        );
    }
}

export default ChoroplethMap;
