import React from "react";
import { useState, useEffect } from "react";
import { useHistory} from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/fr";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// react plugin for creating charts
// @material-ui/core
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";

import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import Table from "components/Table/Table.js";

import styles from "assets/jss/material-kit-pro-react/views/dashboardStyle.js";

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const useStyles = makeStyles(styles);

moment.locale("fr");

const themeDate = createMuiTheme({
  palette: {
    primary: {
      main: "#00acc1"
    }
  }
});

export default function SessionPage() {

  const headers = [
    "Nom",
    "Prénom",
    "Téléphone",
    "Email",
    "Date"
  ];

  const [data, setData] = useState({ formation: ""});
  const [selectedDate, handleDateChange] = useState(new Date());

  const [sessionSelect, setSessionSelect] = React.useState(0);
  const handleSession = event => {
    let code = event.target.value;
    setSessionSelect(event.target.value);
    if(code==="1"){setData({ ...data, formation: 1 })}
    if(code==="2"){setData({ ...data, formation: 2 })}
    if(code==="3"){setData({ ...data, formation: 3 })}
    if(code==="4"){setData({ ...data, formation: 4 })}
    if(code==="5"){setData({ ...data, formation: 5 })}
    if(code==="6"){setData({ ...data, formation: 6 })}
  }

  const history = useHistory();

  useEffect(() => {
    let userjwt = localStorage.getItem('jwt');
    console.log(userjwt);
    if(userjwt!=null){
      retrieveSessions();
      retrieveRdvs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[history]);

  const [sessions, setSessions] = useState([]);
  function retrieveSessions()
  {
    let userjwt = localStorage.getItem('jwt');
    axios.get(`${global.API_URL}/session-informations?_limit=-1`, {
      headers: {
        Authorization:
        `Bearer ${userjwt}`,
      },
    }).then(res => {
      let listSessions = [];
      for (let [index,session] of res.data.entries()) {
        let date = moment(res.data[index].date).format('DD/MM/yyyy');
        let time = moment(session.date).format('HH:mm');
        let listFormations="";
        for(let i=0;i<session.formations.length;i++){
          listFormations+=session.formations[i].type+" "
        }
        let s = [listFormations,date,time,
          <Button justIcon size="sm" simple color="danger" onClick={() => deleteEntry(session.id)}>
            <DeleteOutlineIcon/>
          </Button>];
        listSessions.push(s);
      }
      setSessions(listSessions);
    });
  }

  const [rdvs, setRdvs] = useState([]);
  function retrieveRdvs()
  {
    let userjwt = localStorage.getItem('jwt');
    axios.get(`${global.API_URL}/rdv-sessions?_limit=-1`, {
      headers: {
        Authorization:
        `Bearer ${userjwt}`,
      },
    }).then(res => {
      let listRdvs = [];
      for (let [index,rdv] of res.data.entries()) {
        let date="N/A";
        if(res.data[index].session_information.date)date = moment(res.data[index].session_information.date).format('DD/MM/yyyy');
        let s = [`${rdv.nom}`,`${rdv.prenom}`,`${rdv.phone}`,`${rdv.email}`,date,
          <Button justIcon size="sm" simple color="danger" onClick={() => deleteRDV(rdv.id)}>
            <DeleteOutlineIcon/>
          </Button>];
        listRdvs.push(s);
      }
      setRdvs(listRdvs);
    });
  }

  const deleteRDV = value => {
    let userjwt = localStorage.getItem('jwt');
    const apiUrl = `${global.API_URL}/rdv-sessions/${value}`;
    fetch(apiUrl, {
      method: "DELETE",
      headers: userjwt && { Authorization: `Bearer ${userjwt}` }})
      .then(function(response){
        retrieveRdvs();
      }).catch(function(response){
         console.log('Failed to delete project');
      });
  }

  const deleteEntry = value => {
    let userjwt = localStorage.getItem('jwt');
    const apiUrl = `${global.API_URL}/session-informations/${value}`;
    fetch(apiUrl, {
      method: "DELETE",
      headers: userjwt && { Authorization: `Bearer ${userjwt}` }})
      .then(function(response){
        retrieveSessions();
      }).catch(function(response){
         console.log('Failed to delete project');
      });
  }

  function addSession()
  {
    let userjwt = localStorage.getItem('jwt');
    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify({
         date: selectedDate,
         formations: `${data.formation}`
       }))

    axios.post(`${global.API_URL}/session-informations`,dataForm, {
      headers: {
        Authorization:`Bearer ${userjwt}`,
      },
    }).then(function(response){
      console.log('Success!');
      retrieveSessions();
    })
    .catch(function(){
      console.log('Fail!');
    });
  }

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
              <h4 className={classes.cardTitleWhite}>Session d'information</h4>
          </CardHeader>
          <CardBody>
              <h5 className={classes.subtitle}>Ajouter une session d'information</h5>
              <GridContainer direction="row"
                justify="center"
                alignItems="center">
                <GridItem xs={12} sm={2} md={2}>
                  <div style={{marginBottom:"-10px"}}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                  <ThemeProvider theme={themeDate}>
                  <DateTimePicker style ={{width: '100%'}}
                  disablePast
                  ampm={false}
                  label="Date et heure"
                  value={selectedDate}
                  onChange={handleDateChange}
                  inputProps={{style: {fontSize: 14, textTransform: "uppercase",color: "#3C4858"}}}/>
                  </ThemeProvider>
                  </MuiPickersUtilsProvider>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Formation
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={sessionSelect}
                    onChange={handleSession}
                    inputProps={{
                      name: "simpleSelect",
                      id: "simple-select"
                    }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="3"
                      >
                        Assistante Dentaire
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="1"
                      >
                        Secrétaire Médicale
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="2"
                      >
                        Auxiliaire de Service Vétérinaire
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="4"
                      >
                        Chargé de Gestion et de Management en Structure Animalière
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="5"
                      >
                        Assistant(e) éducatif petite enfance
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value="6"
                      >
                        Assistant(e) juridique
                      </MenuItem>
                    </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <Button color="info" round onClick={addSession}>
                      <AddIcon/> Ajouter
                    </Button>
                  </GridItem>
              </GridContainer>
              <br/>
              <h5 className={classes.subtitle}>Liste des sessions d'information en cours</h5>
              <Table
                striped
                tableHead={[
                  "Formation",
                  "Date",
                  "Heure",
                  "Action"
                ]}
                tableData={sessions}
                customCellClasses={[
                  classes.textLeft,
                  classes.textLeft,
                  classes.textLeft,
                  classes.textRight
                ]}
                customClassesForCells={[0, 1, 2, 3]}
                customHeadCellClasses={[
                  classes.textLeft,
                  classes.textLeft,
                  classes.textLeft,
                  classes.textRight
                ]}
                customHeadClassesForCells={[0, 1, 2, 3]}
              />
          </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader color="info">
          <GridContainer justify="center" alignItems="center">
          <GridItem xs={12} sm={8} md={8}>
          <h4 className={classes.cardTitleWhite}>Rendez vous enregistrés aux sessions d'information</h4>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
          <div className={classes.buttonRight}><CSVLink data={rdvs} headers={headers}><Button color="white" round>Télécharger le fichier Excel</Button></CSVLink></div>
          </GridItem>
          </GridContainer>
        </CardHeader>
        <CardBody>
          <Table
            striped
            tableHead={[
              "Nom",
              "Prénom",
              "Téléphone",
              "Email",
              "Date de session",
              "Action"
            ]}
            tableData={rdvs}
            customCellClasses={[
              classes.textLeft,
              classes.textLeft,
              classes.textLeft,
              classes.textRight
            ]}
            customClassesForCells={[0, 1, 2, 3]}
            customHeadCellClasses={[
              classes.textLeft,
              classes.textLeft,
              classes.textLeft,
              classes.textRight
            ]}
            customHeadClassesForCells={[0, 1, 2, 3]}
          />
        </CardBody>
      </Card>
    </GridItem>
    </GridContainer>
  );
}
