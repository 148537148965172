/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";

import Info from "components/Typography/Info.js";
import Danger from "components/Typography/Danger.js";
import Success from "components/Typography/Success.js";
import Warning from "components/Typography/Warning.js";

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ActuPageStyle.js";

const useStyles = makeStyles(presentationStyle);

const units: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

var path = require("path");

function timeAgo(dateTime) {
  const diff = dateTime.diffNow().shiftTo(...units);
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second';
  const relativeFormatter = new Intl.RelativeTimeFormat('fr', {
    numeric: 'auto',
  });
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit);
};

const FilPage = (props) => {
  const { blogs } = props;
  const classes = useStyles();
  return (

  <div className={classes.container}>
    <div className={classes.section}>
    <GridContainer direction="row" justify="center" alignItems="center">
        {blogs.map((blog) => {
        var datestr_update_prod = timeAgo(DateTime.fromISO(`${blog.updated_at}`));
        return(
        
        <GridItem xs={12} sm={6} md={6}>  
        <Card plain blog className={classes.card}>
          <GridContainer direction="row" justify="center" alignItems="center">
            <GridItem xs={12} sm={7} md={7}>
              <CardHeader image plain>
                <img src={blog.image} alt="..." />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${`${blog.image}`})`,
                    opacity: "1"
                  }}
                />
                <div
                  className={classes.coloredShadow}
                  style={{
                    backgroundImage: `url(${`${blog.image}`})`,
                    opacity: "1"
                  }}
                />
              </CardHeader>
            </GridItem>
            <GridItem xs={12} sm={5} md={5}>
              <Info>
                <h6 className={classes.cardCategory}>{blog.theme}</h6>
              </Info>
              <h4 className={classes.cardTitle}>              
                  {blog.titre}
              </h4>
              <p className={classes.description}>
                {blog.texte}
              </p>
              {blog.auteur.length>0 &&
              <p className={classes.author}>
                par{" "}
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <b>{blog.auteur}</b>
                </a>{" "}
                , {datestr_update_prod}
              </p>}
              {blog.auteur==0 &&
              <p className={classes.author}>
                {datestr_update_prod}
              </p>}
            </GridItem>
          </GridContainer>
        </Card>
        </GridItem>   
        );
        })}
        </GridContainer>
        </div>
</div>
);
}
export default FilPage;
