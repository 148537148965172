/*eslint-disable*/
import React, { useState } from "react";
import { useHistory} from "react-router-dom";
import axios from "axios";
import ReCAPTCHA  from 'react-google-recaptcha';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Paper from '@material-ui/core/Paper';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

import 'leaflet/dist/leaflet.css';
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ContactStyle.js";

import logo_ecosup from "assets/img/ecosup.png";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const useStyles = makeStyles(presentationStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const CustomSkinMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={14}
      defaultCenter={{ lat: 43.12667, lng: 5.93333 }}
      defaultOptions={{
        scrollwheel: true,
        zoomControl: false,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker color="blue" title={"EcoSup Toulon"} position={{ lat: 43.131347432302775, lng: 5.928128534138015 }} />
    </GoogleMap>
  ))
);

export default function ContactPage() {

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ nom: "", prenom: "", email:"", phone:"", texte:""});
  const [lat, setLat] = useState(43.12667)
  const [lng, setLng] = useState(5.93333)
  const [zoom, setZoom] = useState(3);
  const position = [lat, lng];

  const [modalSuccess, setModalSuccess] = React.useState(false);
  const [errors, setErrors] = useState({});

  const [verified, setVerified] = useState(false);
  function onChange(value) {
    console.log("Captcha value:", value);
  }

  function handleValidation(){
    let formIsValid = true;
    let errors = {};

    //Name
    let name = `${data.nom}`;
    if(name.trim() == ""){
       formIsValid = false;
       errors["name"] = "*Veuillez entrer un nom";
    }else{
      if(typeof name !== "undefined"){
         if(!name.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["name"] = "*Le nom doit comporter uniquement des caratères";
         }
      }
    }

    //Prenom
    let prenom = `${data.prenom}`;
    if(prenom.trim() == ""){
       formIsValid = false;
       errors["prenom"] = "*Veuillez entrer un prénom";
    }else{
      if(typeof prenom !== "undefined"){
         if(!prenom.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["prenom"] = "*Le prénom doit comporter uniquement des caratères";
         }
      }
    }

    //Email
    let email = `${data.email}`;
    if(email.trim() == ""){
       formIsValid = false;
       errors["email"] = "*Veuillez entrer une adresse email";
    }else{
      if(typeof email !== "undefined"){
         let lastAtPos = email.lastIndexOf('@');
         let lastDotPos = email.lastIndexOf('.');

         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "*Veuillez entrer une adresse email valide";
          }
     }
    }

    //Phone
    let phone = `${data.phone}`;
    if(phone.trim() == ""){
       formIsValid = false;
       errors["phone"] = "*Veuillez entrer un numéro de téléphone";
    }else{
      if(typeof phone !== "undefined"){
         if(!phone.match(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/)){
            formIsValid = false;
            errors["phone"] = "*Veuillez entrer un numéro de téléphone valide";
         }
      }
    }

    //text
    let texte = `${data.texte}`;
    if(texte.trim() == ""){
       formIsValid = false;
       errors["texte"] = "*Veuillez remplir votre message";
    }

    if(!checkbox){
      formIsValid = false;
      errors["checkbox"] = "*Veuillez acceptez l'enregistrement de vos informations";
    }

   setErrors(errors);
   console.log(errors);
   return formIsValid;
  }

  const [checkbox, setCheckbox] = useState(false);
  const handleCheckbox = (event) => {
    errors["checkbox"]="";
    setCheckbox(event.target.checked);
  };

function sendMessage() {
  let isValid = handleValidation();
  if(isValid){
    setIsLoading(true);
    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify({
         nom: `${data.nom}`,
         prenom: `${data.prenom}`,
         email: `${data.email}`,
         phone: `${data.phone}`,
         texte: `${data.texte}`
       }))

    axios.post(`${global.API_URL}/messages`,dataForm
    ).then(function(response){
    console.log('Success!');
    setIsLoading(false);
    setModalSuccess(true);
  })
  .catch(function(){
    console.log('Fail!');
  });
  }
}
  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="white"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/map.jpg")}
        className={classes.parallax}
      >
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem md={7} sm={7}>
                <h2 className={classes.title}>Envoyez nous un message</h2>
                <p className={classes.description}>
                  Vous pouvez nous contacter pour toutes demandes de renseignements. Un membre de notre équipe vous répondra dès que possible.
                  <br />
                  <br />
                </p>
                <form>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Nom"
                        id="float"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                          required: true}}
                      />
                    <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Prenom"
                        id="float"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                          required: true}}
                      />
                      <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
                    </GridItem>
                  </GridContainer>
                  <CustomInput
                    labelText="Adresse Email"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                      required: true}}
                  />
                  <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
                  <CustomInput
                    labelText="Numéro de téléphone"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                      required: true}}
                  />
                  <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
                  <CustomInput
                    labelText="Votre message"
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: (e) => {setData({ ...data, texte: e.target.value });errors["texte"]=""},
                      multiline: true,
                      rows: 8
                    }}
                  />
                <p style={{color: "red",fontSize: "12px"}}>{errors["texte"]}</p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="default"
                        onChange={handleCheckbox}
                      />
                    }
                    label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
                  />
                  <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
                  <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
                  <div className={classes.textCenter}>
                    <ReCAPTCHA
                        sitekey="6LdPpNUbAAAAAAzBeacb9pTVPJX97WXzfRmtSFCJ"
                        onChange={onChange}
                        size="invisible"
                      />
                    <Button color="success" round onClick={sendMessage}>
                      {isLoading ? "Envoie du message ... " : "Contactez nous"}
                    </Button>
                  </div>
                </form>
              </GridItem>
              <GridItem md={5} sm={5} className={classes.mlAuto}>
                <br />
                <InfoArea
                  className={classes.info}
                  title="Adresse"
                  description={
                    <p>
                      <a target="_blank" href="https://goo.gl/maps/g9Dt1t7gCbfVbMPq7">177 boulevard du docteur Charles Barnier <br />  83000 Toulon</a>  (prendre impasse Barnier)
                    </p>
                  }
                  icon={PinDrop}
                  iconColor="success"
                />
                <InfoArea
                  className={classes.info}
                  title="Email"
                  description={
                    <p>
                      contact@ecosup-toulon.fr
                    </p>
                  }
                  icon={AlternateEmailIcon}
                  iconColor="success"
                />
                <InfoArea
                  className={classes.info}
                  title="Téléphone"
                  description={
                    <p>
                      04 94 22 59 29 <br /> Lundi - Vendredi,
                      8:30 - 18:00
                    </p>
                  }
                  icon={Phone}
                  iconColor="success"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalSuccess}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalSuccess(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Votre message a été envoyé!</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <p>Un membre de notre équipe vous contactera dans les plus bref délais.</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {setModalSuccess(false);history.push('/');}} color="success" round>Retour à la page d'accueil</Button>
          </DialogActions>
        </Dialog>
      </div>

      <SectionFooter/>
    </div>
  );
}
