import {
  container,
  section,
  grayColor,
  title,
  whiteColor,
  hexToRgb,
  description,
  coloredShadow,
  cardTitle
} from "assets/jss/material-kit-pro-react.js";

const descriptionStyle = {
  container,
  coloredShadow,
  title: {
    ...title,
    textAlign: "center"
  },
  description: {
    color: grayColor[0],
    textAlign: "center"
  },
  bandeau: {
    marginTop: "-20px",
    color: whiteColor[0],
    textAlign: "center",
  },
  cardTitleAbsolute: {
    ...cardTitle,
    position: "absolute !important",
    bottom: "15px !important",
    left: "15px !important",
    color: whiteColor + " !important",
    fontSize: "1.125rem !important",
    textShadow: "0 2px 5px rgba(" + hexToRgb(grayColor[9]) + ", 0.5) !important"
  },
  section: {
    ...section,
    padding: "90px 0px",
    "& h4$description": {
      fontSize: "1.5em"
    }
  },
  sectionTop: {
    ...section,
    paddingTop: "90px",
    "& h4$description": {
      fontSize: "1.5em"
    }
  },
  sectionBottom: {
    ...section,
    paddingBottom: "70px",
    "& h4$description": {
      fontSize: "1.5em"
    }
  },
  background: {
    background: "blue"
  },
  title2: {
    ...title,
    textAlign: "center",
    marginTop: "5rem",
    marginBottom: "2rem",
  },
  features: {
    textAlign: "center !important",
    paddingTop: "0px",
    paddingBottom: "0px",
    justifyContent:"center",
    alignItems:"center",
    "& p": {
      fontSize: "14px",
      lineHeight: "1.6em"
    }
  },
  cardTitleWhite: {
    "&, & a": {
      ...title,
      marginTop: ".425rem",
      marginBottom: ".425rem",
      minHeight: "7rem",
      color: whiteColor + " !important"
    }
  },
  cardDescriptionWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ", 0.8)",
    marginTop: ".425rem",
    marginBottom: ".425rem",
    minHeight: "7rem",
  },
  cardDescription: {
    ...description
  },
  cardLogo: {
    marginTop: "40px",
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
};

export default descriptionStyle;
