/*eslint-disable*/
import React, { useState }  from "react";
import { useHistory} from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA  from 'react-google-recaptcha';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
// sections for this page
import SectionDescription from "views/PresentationPage/Sections/SectionDescription.js";
import SectionFooter from "views/footer/PageFooter.js"

import presentationStyle from "assets/jss/material-kit-pro-react/views/echosup/ContactStyle.js";

import logo_ecosup from "assets/img/ecosup.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(presentationStyle);

export default function FormPage() {

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ formation: "", nom: "", prenom: "", email:"", phone:"", adresse:"", code:"", ville:""});
  const [errors, setErrors] = useState({});
  const [modalSuccess, setModalSuccess] = React.useState(false);

  const [verified, setVerified] = useState(false);
  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const [simpleSelect, setSimpleSelect] = React.useState("");
  const handleSimple = event => {
    let code = event.target.value;
    setSimpleSelect(event.target.value);
    if(code=="1"){setData({ ...data, formation: 1 })}
    if(code=="2"){setData({ ...data, formation: 2 })}
    if(code=="3"){setData({ ...data, formation: 3 })}
    if(code=="4"){setData({ ...data, formation: 4 })}
    if(code=="5"){setData({ ...data, formation: 5 })}
    errors["formation"]="";
  }

  function handleValidation(){
    let formIsValid = true;
    let errors = {};

    //formation
    let formation = `${data.formation}`;
    if(!formation.match(/[1-4]{1}/)){
       formIsValid = false;
       errors["formation"] = "*Veuillez selectionner une formation";
    }

    //Name
    let name = `${data.nom}`;
    if(name.trim() == ""){
       formIsValid = false;
       errors["name"] = "*Veuillez entrer un nom";
    }else{
      if(typeof name !== "undefined"){
         if(!name.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["name"] = "*Le nom doit comporter uniquement des caratères";
         }
      }
    }

    //Prenom
    let prenom = `${data.prenom}`;
    if(prenom.trim() == ""){
       formIsValid = false;
       errors["prenom"] = "*Veuillez entrer un prénom";
    }else{
      if(typeof prenom !== "undefined"){
         if(!prenom.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["prenom"] = "*Le prénom doit comporter uniquement des caratères";
         }
      }
    }

    //Email
    let email = `${data.email}`;
    if(email.trim() == ""){
       formIsValid = false;
       errors["email"] = "*Veuillez entrer une adresse email";
    }else{
      if(typeof email !== "undefined"){
         let lastAtPos = email.lastIndexOf('@');
         let lastDotPos = email.lastIndexOf('.');

         if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
            formIsValid = false;
            errors["email"] = "*Veuillez entrer une adresse email valide";
          }
     }
    }

    //Phone
    let phone = `${data.phone}`;
    if(phone.trim() == ""){
       formIsValid = false;
       errors["phone"] = "*Veuillez entrer un numéro de téléphone";
    }else{
      if(typeof phone !== "undefined"){
         if(!phone.match(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/)){
            formIsValid = false;
            errors["phone"] = "*Veuillez entrer un numéro de téléphone valide";
         }
      }
    }

    //Adresse
    let adresse = `${data.adresse}`;
    if(adresse.trim() == ""){
       formIsValid = false;
       errors["adresse"] = "*Veuillez entrer une adresse";
    }

    //Code
    let code = `${data.code}`;
    if(code.trim() == ""){
       formIsValid = false;
       errors["code"] = "*Veuillez entrer un code postal";
    }else{
      if(typeof code !== "undefined"){
         if(!code.match(/[0-9]{5}/)){
            formIsValid = false;
            errors["code"] = "*Le code postal doit comporter 5 chiffres";
         }
      }
    }

    //Ville
    let ville = `${data.ville}`;
    if(ville.trim() == ""){
       formIsValid = false;
       errors["ville"] = "*Veuillez entrer une ville";
    }else{
      if(typeof ville !== "undefined"){
         if(!ville.match(/^[a-zA-Z]+$/)){
            formIsValid = false;
            errors["ville"] = "*La ville doit comporter uniquement des caratères";
         }
      }
    }

    if(!checkbox){
      formIsValid = false;
      errors["checkbox"] = "*Veuillez acceptez l'enregistrement de vos informations";
    }

   setErrors(errors);
   console.log(errors);
   return formIsValid;
  }

  const [checkbox, setCheckbox] = useState(false);
  const handleCheckbox = (event) => {
    errors["checkbox"]="";
    setCheckbox(event.target.checked);
  };

  function sendInscription() {
    //first we validate the form
    let isValid = handleValidation();
    if(isValid){
    setIsLoading(true);
    const dataForm = new FormData();
    dataForm.append('data', JSON.stringify({
         nom: `${data.nom}`,
         prenom: `${data.prenom}`,
         email: `${data.email}`,
         phone: `${data.phone}`,
         adresse: `${data.adresse}`,
         code_postal: `${data.code}`,
         ville: `${data.ville}`,
         formation: `${data.formation}`
       }))

      axios.post( `${global.API_URL}/inscriptions`,dataForm
      ).then(function(response){
      console.log('Success!');
      setIsLoading(false);
      setModalSuccess(true);
    })
    .catch(function(){
      console.log('Fail!');
    });
    }
  }

  const classes = useStyles();
  return (
    <div>
      <Header
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 50,
          color: "white"
        }}
      />
      <Parallax
        image={require("assets/img/form.jpg")}
        className={classes.parallax}
        filter="dark"
      >
        <div className={classes.container2}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={8} sm={4} md={4}>
                    <img
                      src={logo_ecosup} alt="..." className={classes.imgRounded + " " + classes.imgFluid}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h1 className={classes.titleTop}>
                      Formulaire d'inscription
                    </h1>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
          <div className={classes.container}>
            <h2 className={classes.title}>Inscription Toulon</h2>
            <GridContainer>
              <GridItem md={10} sm={10}>
                <p className={classes.description}>
                  Veuillez remplir le formulaire ci dessous. Un membre de notre équipe vous contactera prochainement.
                  <br />
                  <br />
                </p>
                <form>
                <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Formation
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={simpleSelect}
                  onChange={handleSimple}
                  inputProps={{
                    name: "simpleSelect",
                    id: "simple-select"
                  }}
                  >
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="3"
                    >
                      Assistante Dentaire
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="1"
                    >
                      Secrétaire Médicale
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="2"
                    >
                      Auxiliaire de Service Vétérinaire
                    </MenuItem>
                    {/* <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="4"
                    >
                      Chargé de Gestion et de Management en Structure Animalière
                    </MenuItem> */}
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="5"
                    >
                      Accompagnant(e) éducatif petite enfance
                    </MenuItem>
                  </Select>
                  <p style={{color: "red",fontSize: "12px"}}>{errors["formation"]}</p>
                  </FormControl>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Nom"
                        id="float"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) => {setData({ ...data, nom: e.target.value });errors["name"]=""},
                          required: true}}
                      />
                      <p style={{color: "red",fontSize: "12px"}}>{errors["name"]}</p>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Prenom"
                        id="float"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) => {setData({ ...data, prenom: e.target.value });errors["prenom"]=""},
                          required: true}}
                      />
                      <p style={{color: "red",fontSize: "12px"}}>{errors["prenom"]}</p>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Adresse Email"
                        id="float"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) => {setData({ ...data, email: e.target.value });errors["email"]=""},
                          required: true}}
                      />
                    <p style={{color: "red",fontSize: "12px"}}>{errors["email"]}</p>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Numéro de téléphone"
                        id="float"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) => {setData({ ...data, phone: e.target.value });errors["phone"]=""},
                          required: true}}
                      />
                    <p style={{color: "red",fontSize: "12px"}}>{errors["phone"]}</p>
                    </GridItem>
                  </GridContainer>
                  <CustomInput
                    labelText="N° de voie, rue, etc ..."
                    id="float"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: (e) => {setData({ ...data, adresse: e.target.value });errors["adresse"]=""},
                      required: true}}
                    />
                    <p style={{color: "red",fontSize: "12px"}}>{errors["adresse"]}</p>
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Code Postal"
                        id="float"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) => {setData({ ...data, code: e.target.value });errors["code"]=""},
                          required: true}}
                        />
                      <p style={{color: "red",fontSize: "12px"}}>{errors["code"]}</p>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <CustomInput
                        labelText="Ville"
                        id="float"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) => {setData({ ...data, ville: e.target.value });errors["ville"]=""},
                          required: true}}
                      />
                      <p style={{color: "red",fontSize: "12px"}}>{errors["ville"]}</p>
                    </GridItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="default"
                          onChange={handleCheckbox}
                        />
                      }
                      label="J'accepte l'enregistrement de mes informations dans la base de données de l'école."
                    />
                    <p style={{color: "red",fontSize: "12px"}}>{errors["checkbox"]}</p>
                    <p className={classes.rgpd}>Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Ecosup Toulon pour vous tenir informés d'informations importantes relatives à votre admissibilité. Elles sont conservées pendant 1 an et sont destinées au service des admissions. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant : contact@ecosup-toulon.fr Nous vous informons de l’existence de la liste d'opposition au démarchage téléphonique « Bloctel », sur laquelle vous pouvez vous inscrire ici : https://conso.bloctel.fr/</p>
                  </GridContainer>
                  <br/>
                  <div className={classes.textCenter}>
                    <ReCAPTCHA
                        sitekey="6LdPpNUbAAAAAAzBeacb9pTVPJX97WXzfRmtSFCJ"
                        onChange={onChange}
                        size="invisible"
                      />
                    <Button color="success" round onClick={sendInscription}>
                      {isLoading ? "Envoie de l'inscription ... " : "Je m'inscris"}
                    </Button>
                  </div>
                </form>
              </GridItem>
            </GridContainer>
          </div>
        </div>
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={modalSuccess}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setModalSuccess(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
          <h4 className={classes.modalTitle}>Votre insciption a été enregistrée!</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}
          >
            <p>Un membre de notre équipe vous contactera dans les plus bref délais.</p>
          </DialogContent>
          <DialogActions
            className={classes.modalFooter + " " + classes.modalFooterCenter}
          >
            <Button onClick={() => {setModalSuccess(false);history.push('/');}} color="success" round>Retour à la page d'accueil</Button>
          </DialogActions>
        </Dialog>
      </div>

      <SectionFooter/>
    </div>
  );
}
